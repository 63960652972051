/** css import */
import './scss/CustomApp.scss';

import React from 'react';
import {Route, Switch} from 'react-router-dom';
import AluListComponent from './components/Alu/AluListComponent';
import PowderListComponent from './components/Powder/PowderListComponent';
import RepairListComponent from './components/Repairs/RepairListComponent';
import OrderComponent from './components/Order/OrderComponent';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faList, faAngleRight, faAngleLeft, faCalculator} from '@fortawesome/free-solid-svg-icons';
import {
  faStickyNote,
  faQuestionCircle,
  faHandPointRight,
  faCalendarAlt,
  faClock,
} from '@fortawesome/free-regular-svg-icons';
import {
  faSirenOn,
  faSprayCan,
  faTools,
  faBoxOpen,
  faFileInvoiceDollar,
  faCalendarExclamation,
  faBoxes,
  faClipboardListCheck,
  faCartShoppingFast,
  faDollyFlatbed,
  faRulerHorizontal,
  faPlusCircle,
  faPencil,
  faTimes,
  faArrowCircleRight,
  faGaugeHigh,
  faFileCheck,
  faFile,
  faArrowDownToLine,
  faTruck,
} from '@fortawesome/pro-regular-svg-icons';
import {
  TwoAppFrame,
  AppMenuItemTemplate,
  MessageService,
  AppMenuItemSeparator,
  AppMenuItem,
  AuthService,
  AppContext,
  ToastService,
  UsersService,
  TwoToast,
} from 'two-app-ui';
import OrdersService from './services/OrdersService';
import OosService from './services/OosService';
import FactoriesService from './services/FactoriesService';
import ShipmentsService from './services/ShipmentsService';
import PurchaseOrdersService from './services/PurchaseOrdersService';
import SuppliersService from './services/SuppliersService';
import {Subscription} from 'rxjs';
import PurchaseOrderListComponent from './components/PurchaseOrders/PurchaseOrderListComponent';
import config from './config/config';
import InventoryItemListComponent from './components/Inventory/InventoryItemListComponent';
import BomListComponent from './components/AllOrders/BomListComponent';
import AlarmsService from './services/AlarmsService';
import AlarmListComponent from './components/Alarms/AlarmListComponent';
import DocumentsService from './services/DocumentsService';
import InventoryService from './services/InventoryService';
import StandardOrderListComponent from './components/StandardOrders/StandardOrderListComponent';
import {AuthenticatedTwoUser, Factory} from 'two-core';
import ComponentOrders from './components/Components/ComponentOrders';
import OosListComponent from './components/Oos/OosListComponent';
import BomService from './services/BomService';
import TleService from './services/TleService';
import UserListComponent from './components/Users/UserListComponent';
import {MenuItemOptions} from 'primereact/menuitem';
import AllOrdersComponet from './components/AllOrders/AllOrdersComponent';
import LeadTimesComponent from './components/LeadTimes/LeadTimesComponent';
import FactoryTleService from './services/FactoryTleService';
import FactoryOrderService from './services/FactoryOrdersService';
import FactoryOrdersService from './services/FactoryOrdersService';
import PurchaseOrderComponent from './components/PurchaseOrder/PurchaseOrderComponent';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import InventoryItemComponent from './components/InventoryItem/InventoryItemComponent';
import OosComponent from './components/OosDetail/OosComponent';
import Waiting4MaterialOrderListComponent from './components/Waiting4MaterialOrders/Waiting4MaterialOrderListComponent';
import SupplyItemsService from './services/SupplyItemsService';
import {FactoryDropDownOption} from './services/FactoryDropDownOption';
import {Toast} from 'primereact/toast';
import {SchedulerPage} from './components/SchedulerPage/SchedulerPage';
import CompaniesService from './services/CompaniesService';
import {CompletedPage} from './components/CompletedPage/CompletedPage';
import {StockTakesPage} from './components/StockTake/StockTakesPage';
import StockTakesService from './services/StockTakesService';
import StockTakePage from './components/StockTake/StockTakePage';
import ReturnsPage from './components/ReturnsPage/ReturnsPage';
import NewOrdersPage from './components/NewOrdersPage/NewOrdersPage';
import {StockTransfersService} from './services/StockTransferService';
import {StockTransferPage} from './components/StockTransferPage/StockTransferPage';

const authService = new AuthService();
const ordersService = new OrdersService(authService);
const factoryOrderService = new FactoryOrderService(authService);
const tleService = new TleService(authService);
const oosService = new OosService(authService);
const bomService = new BomService(authService);
const factoriesService = new FactoriesService(authService);
const shipmentsService = new ShipmentsService(authService);
const purchaseOrdersService = new PurchaseOrdersService(authService);
const suppliersService = new SuppliersService(authService);
const supplyItemsService = new SupplyItemsService(authService);
const alarmsService = new AlarmsService(authService);
const documentsService = new DocumentsService(authService);
const inventoryService = new InventoryService(authService);
const supplierService = new SuppliersService(authService);
const factoryTleService = new FactoryTleService(authService);
const factoryOrdersService = new FactoryOrdersService(authService);
const toastService = new ToastService();
const usersService = new UsersService(authService);
const toastRef = React.createRef<Toast>();
const twoToast = new TwoToast(toastRef);
const companiesService = new CompaniesService(authService);
const stockTakesService = new StockTakesService(authService);
const stockTransfersService = new StockTransfersService(authService);

export type AppContextProps = {
  ordersService: OrdersService;
  factoryOrderService: FactoryOrderService;
  tleService: TleService;
  factoriesService: FactoriesService;
  oosService: OosService;
  bomService: BomService;
  shipmentsService: ShipmentsService;
  purchaseOrdersService: PurchaseOrdersService;
  suppliersService: SuppliersService;
  alarmsService: AlarmsService;
  documentsService: DocumentsService;
  inventoryService: InventoryService;
  supplierService: SuppliersService;
  factoryTleService: FactoryTleService;
  factoryOrdersService: FactoryOrdersService;
  authService: AuthService;
  toastService: ToastService;
  usersService: UsersService;
  supplyItemsService: SupplyItemsService;
  twoToast: TwoToast;
  companiesService: CompaniesService;
  stockTakesService: StockTakesService;
  stockTransfersService: StockTransfersService;
};

library.add(
  faList,
  faStickyNote,
  faQuestionCircle,
  faHandPointRight,
  faTools,
  faSirenOn,
  faCalendarAlt,
  faSprayCan,
  faBoxOpen,
  faFileInvoiceDollar,
  faCalendarExclamation,
  faAngleRight,
  faAngleLeft,
  faBoxes,
  faDollyFlatbed,
  faClipboardListCheck,
  faCartShoppingFast,
  faRulerHorizontal,
  faClock,
  faPlusCircle,
  faPencil,
  faTimes,
  faClock,
  faArrowCircleRight
);
interface State {
  currentFactory?: Pick<Factory, 'id' | 'key' | 'name_long'>;
  menuItems: AppMenuItem[];
}
class App extends React.Component<{}, State> {
  static contextType = AppContext;
  subscription: Subscription = new Subscription();
  timer: NodeJS.Timeout | null;

  constructor(props = {}) {
    super(props);

    this.state = {
      currentFactory: undefined,
      menuItems: [],
    };

    this.onFactoryChange = this.onFactoryChange.bind(this);
    this.getMenuItems = this.getMenuItems.bind(this);
    this.initSelectedFactory = this.initSelectedFactory.bind(this);
    this.timer = null;
  }

  async componentDidMount() {
    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === 'loggedin') {
        this.setMenuItemBadges();
      }
    });

    this.timer = setInterval(() => {
      this.setMenuItemBadges();
    }, config().system?.badgeInterval);

    this.setMenuItemBadges();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  async initSelectedFactory() {
    try {
      if (await authService.isSessionValid()) {
        const user: AuthenticatedTwoUser | undefined = usersService.getCurrentTwoUser();
        if (user) {
          let currentFactoryId = localStorage.getItem('current factory') ?? '';
          const factories = user.factories ?? [];
          if ((currentFactoryId === '' || currentFactoryId === 'undefined') && factories.length) {
            currentFactoryId = factories[0].id!;
          }
          const currentFactory = factories.find(f => f.id === currentFactoryId);
          const menuItems = this.getMenuItems();
          if (currentFactory !== undefined) {
            localStorage.setItem('current factory', currentFactoryId);
            localStorage.setItem('current factory key', currentFactory.key ?? '');
          }
          MessageService.sendMessage('top-selection-loaded');
          this.setState({
            currentFactory: currentFactory,
            menuItems: menuItems,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async setMenuItemBadges() {
    // adHocService.getMenuBadgeNumbers().then(data => {
    //   MenuBadgeService.setMenuBadges(data);
    // });
  }

  getMenuItemSeparator() {
    return <AppMenuItemSeparator />;
  }

  getMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];
    menuItems.push(
      // {
      //   label: 'Alarms',
      //   faIcon: faSirenOn,
      //   badgeId: 'alarmBadge',
      //   to: '/alarms',
      //   template: (item: AppMenuItem, options: MenuItemOptions) => {
      //     return <AppMenuItemTemplate item={item} options={options} />;
      //   },
      // },
      {
        label: 'Dashboard',
        faIcon: faGaugeHigh,
        to: '/dashboard',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        separator: true,
        template: () => {
          return this.getMenuItemSeparator();
        },
      },
      // {
      //   label: 'Waiting 4 Material',
      //   faIcon: faCartShoppingFast,
      //   badgeId: 'waiting4MaterialBadge',
      //   to: '/waiting-4-material',
      //   template: (item: AppMenuItem, options: MenuItemOptions) => {
      //     return <AppMenuItemTemplate item={item} options={options} />
      //   },
      // },
      {
        label: 'New',
        faIcon: faFile,
        badgeId: 'newBadge',
        to: '/new-orders',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        label: 'Scheduler',
        faIcon: faCalendarAlt,
        to: '/scheduler',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        label: 'Completed',
        faIcon: faFileCheck,
        to: '/completed',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        label: 'All orders',
        faIcon: ['fas', 'list'],
        to: '/orders',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        separator: true,
        template: () => {
          return this.getMenuItemSeparator();
        },
      }
    );
    if (usersService.hasPermission('menu-access-lead-times')) {
      menuItems.push(
        {
          label: 'Lead Times',
          faIcon: faClock,
          to: '/lead-times',
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
        },
        {
          separator: true,
          template: () => {
            return this.getMenuItemSeparator();
          },
        }
      );
    }
    menuItems.push(
      {
        label: 'Alu',
        faIcon: faRulerHorizontal,
        badgeId: 'aluBadge',
        to: '/alus',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        label: 'Powder',
        faIcon: faSprayCan,
        badgeId: 'powderBadge',
        to: '/powders',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        label: 'Returns',
        faIcon: faArrowDownToLine,
        badgeId: 'returnsBadge',
        to: '/returns',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        separator: true,
        template: () => {
          return this.getMenuItemSeparator();
        },
      }
    );
    if (usersService.hasPermission('menu-access-inventory')) {
      menuItems.push({
        label: 'Inventory',
        faIcon: faBoxOpen,
        badgeId: 'inventoryBadge',
        to: '/inventory',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      });
    }
    menuItems.push({
      label: 'POs',
      faIcon: faFileInvoiceDollar,
      to: '/purchase-orders',
      badgeId: 'posBadge',
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
    });

    if (usersService.hasPermission('menu-access-stock-takes')) {
      menuItems.push({
        label: 'Stock Takes',
        faIcon: faCalculator,
        to: '/stock-takes',
        badgeId: 'stockTakesBadge',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      });
    }
    menuItems.push({
      label: 'Stock Transfers',
      faIcon: faTruck,
      to: '/stock-transfers',
      badgeId: 'stockTransfersBadge',
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
    });
    menuItems.push(
      {
        label: 'OOS',
        faIcon: faCalendarExclamation,
        badgeId: 'oosBadge',
        to: '/oos',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      }
      // {
      //   separator: true,
      //   template: () => {
      //     return this.getMenuItemSeparator();
      //   },
      // },
      // {
      //   label: 'Repairs',
      //   faIcon: faTools,
      //   badgeId: 'repairBadge',
      //   to: '/repairs',
      //   template: (item: AppMenuItem, options: MenuItemOptions) => {
      //     return <AppMenuItemTemplate item={item} options={options} />;
      //   },
      // },
      // {
      //   label: 'Components',
      //   faIcon: faDollyFlatbed,
      //   badgeId: 'componentBadge',
      //   to: '/components',
      //   template: (item: AppMenuItem, options: MenuItemOptions) => {
      //     return <AppMenuItemTemplate item={item} options={options} />;
      //   },
      // },
      // {
      //   label: 'Standard',
      //   faIcon: faClipboardListCheck,
      //   badgeId: 'standardBadge',
      //   to: '/standard-orders',
      //   template: (item: AppMenuItem, options: MenuItemOptions) => {
      //     return <AppMenuItemTemplate item={item} options={options} />;
      //   },
      // },
    );
    return menuItems;
  }

  async onFactoryChange(factory: Factory) {
    this.setState({
      currentFactory: factory,
    });
    await localStorage.setItem('current factory', factory.id!);
    await localStorage.setItem('current factory key', factory?.key ?? '');
    MessageService.sendMessage({
      name: 'top-selection-changed',
      value: factory.id!,
    });
  }

  renderTopFrame(
    factories: Pick<Factory, 'id' | 'key' | 'name_long'>[],
    currentFactory?: Pick<Factory, 'id' | 'key' | 'name_long'>
  ) {
    return (
      <div className="topframe">
        <div className="dropdown-top">
          <Dropdown
            value={currentFactory}
            options={factories}
            onChange={e => this.onFactoryChange(e.value)}
            placeholder="Select option"
            optionLabel="name_long"
            dataKey="id"
          />
        </div>
      </div>
    );
  }

  render() {
    const {menuItems, currentFactory} = this.state;
    const values: AppContextProps = {
      ordersService: ordersService,
      factoryOrderService: factoryOrderService,
      tleService: tleService,
      factoriesService: factoriesService,
      oosService: oosService,
      bomService: bomService,
      shipmentsService: shipmentsService,
      purchaseOrdersService: purchaseOrdersService,
      suppliersService: suppliersService,
      supplyItemsService: supplyItemsService,
      alarmsService: alarmsService,
      documentsService: documentsService,
      inventoryService: inventoryService,
      supplierService: supplierService,
      factoryTleService: factoryTleService,
      factoryOrdersService: factoryOrdersService,
      authService: authService,
      toastService: toastService,
      usersService: usersService,
      twoToast: twoToast,
      companiesService: companiesService,
      stockTakesService: stockTakesService,
      stockTransfersService: stockTransfersService,
    };

    const factories = usersService.getCurrentTwoUser()?.factories ?? [];
    return (
      <>
        <TwoAppFrame
          menuItems={menuItems}
          contextValues={values}
          topFrame={this.renderTopFrame(factories, currentFactory)}
          onSuccessfulInit={() => this.initSelectedFactory()}
        >
          <>
            <Switch>
              <Route path="/order/:id">
                <OrderComponent />
              </Route>
              <Route path="/orders">
                <AllOrdersComponet />
              </Route>
              <Route path="/inventory-item/:id">
                <InventoryItemComponent />
              </Route>
              <Route path="/inventory">
                <InventoryItemListComponent />
              </Route>
              <Route path="/alus">
                <AluListComponent />
              </Route>
              <Route path="/powders">
                <PowderListComponent />
              </Route>
              <Route path="/repairs">
                <RepairListComponent />
              </Route>
              <Route path="/oos-item/:id">
                <OosComponent />
              </Route>
              <Route path="/oos">
                <OosListComponent />
              </Route>
              <Route path="/boms/:id">
                <BomListComponent />
              </Route>
              <Route path="/purchase-order/:id">
                <PurchaseOrderComponent />
              </Route>
              <Route path="/purchase-orders">
                <PurchaseOrderListComponent />
              </Route>
              <Route path="/alarms">
                <AlarmListComponent />
              </Route>
              <Route path="/components">
                <ComponentOrders />
              </Route>
              <Route path="/standard-orders">
                <StandardOrderListComponent />
              </Route>
              <Route path="/waiting-4-material">
                <Waiting4MaterialOrderListComponent />
              </Route>
              <Route path="/users">
                <UserListComponent />
              </Route>
              <Route path="/scheduler">
                <SchedulerPage />
              </Route>
              <Route path="/completed">
                <CompletedPage />
              </Route>
              <Route path="/lead-times">
                <LeadTimesComponent />
              </Route>
              <Route path="/new-orders">
                <NewOrdersPage />
              </Route>
              <Route path="/returns">
                <ReturnsPage />
              </Route>
              <Route path="/stock-takes">
                <StockTakesPage />
              </Route>
              <Route path="/stock-take/:id">
                <StockTakePage />
              </Route>
              <Route path="/stock-transfers">
                <StockTransferPage />
              </Route>
            </Switch>
          </>
        </TwoAppFrame>
        <Toast ref={toastRef} />
      </>
    );
  }
}

export default App;

import React from 'react';
import {InputText} from 'primereact/inputtext';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faPrint, faPlus} from '@fortawesome/free-solid-svg-icons';
import {ShipmentItem, ShipmentItemDimension} from 'two-core';
import {TwoDialog} from 'two-app-ui';
import {InputNumber, InputNumberValueChangeParams} from 'primereact/inputnumber';
import {ShipmentItemContentDetail} from 'two-core/src/shipment';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';

library.add(faPrint, faPlus);
interface Props {
  label: ShipmentItem;
  onNewLabelUpdate: (labelPatch: Partial<ShipmentItem>) => void;
  onSave: () => void;
  onHide: () => void;
  externalLabelOptions: string[];
}

const NewLabelDialog = ({label, onNewLabelUpdate, onSave, onHide, externalLabelOptions}: Props) => {
  const isNewFormat = !!label?.content_detail;
  const onContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onNewLabelUpdate({content: e.target.value});
  };

  const onContentDetailChange = (e: React.ChangeEvent<HTMLInputElement>, key: keyof ShipmentItemContentDetail) => {
    onNewLabelUpdate({
      content_detail: {
        ...label.content_detail,
        [key]: e.target.value,
      },
    });
  };

  const onDimensionChange = (e: InputNumberValueChangeParams, key: keyof ShipmentItemDimension) => {
    onNewLabelUpdate({
      dimensions: {
        ...label.dimensions,
        [key]: Number(e.target.value),
      },
    });
  };

  return (
    <TwoDialog
      header={`Add Label for ${label?.order_id}`}
      showDialog
      style={{width: '50vw'}}
      breakpoints={{'768px': '75vw', '576px': '90vw'}}
      onHide={onHide}
      onSave={onSave}
    >
      <div className="p-grid w-100">
        {/*content*/}
        {!isNewFormat && (
          <div className="p-col-12">
            <div className="p-grid ">
              <div className="p-col-1 p-as-center text-align-right">
                <label>content</label>
              </div>
              <div className="p-col-10">
                <InputText value={label?.content ?? ''} onChange={onContentChange} className="w-100" />
              </div>
            </div>
          </div>
        )}

        {/*content_detail*/}
        {isNewFormat && (
          <>
            <div className="p-col-12">
              <div className="p-grid ">
                <div className="p-col-1 p-as-center text-align-right">
                  <label>item</label>
                </div>
                <div className="p-col-10">
                  <InputText
                    value={label?.content_detail?.order_item ?? ''}
                    onChange={e => onContentDetailChange(e, 'order_item')}
                    className="w-100"
                  />
                </div>
              </div>
            </div>
            <div className="p-col-12">
              <div className="p-grid ">
                <div className="p-col-1 p-as-center text-align-right">
                  <label>location</label>
                </div>
                <div className="p-col-10">
                  <InputText
                    value={label?.content_detail?.location ?? ''}
                    onChange={e => onContentDetailChange(e, 'location')}
                    className="w-100"
                  />
                </div>
              </div>
            </div>
            <div className="p-col-12">
              <div className="p-grid ">
                <div className="p-col-1 p-as-center text-align-right">
                  <label>w x d</label>
                </div>
                <div className="p-col-10">
                  <InputText
                    value={label?.content_detail?.more_detail ?? ''}
                    onChange={e => onContentDetailChange(e, 'more_detail')}
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {/*width*/}
        <div className="p-col-6">
          <div className="p-grid ">
            <div className="p-col-2 p-as-center text-align-right">
              <label>width</label>
            </div>
            <div className="p-col-8">
              <InputNumber
                className="w-100"
                value={label?.dimensions?.width ?? ''}
                onValueChange={e => onDimensionChange(e, 'width')}
                mode="decimal"
                inputMode="decimal"
                minFractionDigits={0}
                maxFractionDigits={4}
                min={0}
                locale="en-AU"
              />
            </div>
            <div className="p-col-2 p-as-center">
              <label>mm</label>
            </div>
          </div>
        </div>
        {/*height*/}
        <div className="p-col-6">
          <div className="p-grid ">
            <div className="p-col-2 p-as-center text-align-right">
              <label>height</label>
            </div>
            <div className="p-col-8">
              <InputNumber
                className="w-100"
                value={label?.dimensions?.height}
                onValueChange={e => onDimensionChange(e, 'height')}
                mode="decimal"
                inputMode="decimal"
                minFractionDigits={0}
                maxFractionDigits={4}
                min={0}
                locale="en-AU"
              />
            </div>
            <div className="p-col-2 p-as-center">
              <label>mm</label>
            </div>
          </div>
        </div>
        {/*depth*/}
        <div className="p-col-6">
          <div className="p-grid ">
            <div className="p-col-2 p-as-center text-align-right">
              <label>depth</label>
            </div>
            <div className="p-col-8">
              <InputNumber
                className="w-100"
                value={label.dimensions?.depth}
                onValueChange={e => onDimensionChange(e, 'depth')}
                mode="decimal"
                inputMode="decimal"
                minFractionDigits={0}
                maxFractionDigits={4}
                min={0}
                locale="en-AU"
              />
            </div>
            <div className="p-col-2 p-as-center">
              <label>mm</label>
            </div>
          </div>
        </div>
        {/*weight*/}
        <div className="p-col-6">
          <div className="p-grid ">
            <div className="p-col-2 p-as-center text-align-right">
              <label>weight</label>
            </div>
            <div className="p-col-8">
              <InputNumber
                className="w-100"
                value={label.dimensions?.weight}
                onValueChange={e => onDimensionChange(e, 'weight')}
                mode="decimal"
                inputMode="decimal"
                minFractionDigits={0}
                maxFractionDigits={4}
                min={0}
                locale="en-AU"
              />
            </div>
            <div className="p-col-2 p-as-center">
              <label>kg</label>
            </div>
          </div>
        </div>

        {/*ff2 external label*/}
        <div className="p-col-12">
          <div className="p-grid ">
            <div className="p-col-1 p-as-center text-align-right">
              <label>ff2 label</label>
            </div>
            <div className="p-col-10">
              <Dropdown
                className={'w-100'}
                options={externalLabelOptions}
                value={label.ext_label}
                onChange={(e: DropdownChangeParams) => {
                  onNewLabelUpdate({ext_label: e.target.value});
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </TwoDialog>
  );
};

export default NewLabelDialog;

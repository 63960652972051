import React from 'react';
import {StockTransferList} from '../StockTransfer/StockTransferList';

export const StockTransferPage = () => {
  return (
    <div id="stock_transfer_page" className="page-container">
      <StockTransferList />
    </div>
  );
};

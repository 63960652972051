import React from 'react';
import {InputText} from 'primereact/inputtext';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {Column, ColumnBodyOptions} from 'primereact/column';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faPrint, faPlus, faTimes, faCopy} from '@fortawesome/pro-regular-svg-icons';
import {Address, Order, ShipmentItem, ShipmentItemContentDetail, ShipmentItemDimension} from 'two-core';
import {TwoDataTable, AppColumnMenuBodyTemplate, AppMenuItem, AppMenuItemTemplate} from 'two-app-ui';
import {MenuItemOptions} from 'primereact/menuitem';
import {InputNumber} from 'primereact/inputnumber';
import {colourvueExtLabels, curtainsExtLabels, shadesolExtLabels} from '../../config/factoryConstants';

library.add(faPrint, faPlus, faTimes, faCopy);

interface Props {
  labels: ShipmentItem[];
  order: Order;
  selectedLabels: ShipmentItem[];
  onLabelAdd: (orderId: string) => void;
  onSelectedLabelsCopy: () => void;
  onSelectedLabelsDelete: () => void;
  onSelectedItemsChange: (labels: ShipmentItem[]) => void;
  onLabelUpdate: (orderId: string, labelId: number, labelPatch: Partial<ShipmentItem>) => void;
  readonly: boolean;
}

type FieldName =
  | 'index'
  | 'count'
  | 'order_item'
  | 'location'
  | 'width'
  | 'height'
  | 'depth'
  | 'weight'
  | 'more_detail'
  | 'ext_label'
  | 'content'; // 'content' is used for backward compatibility

const ShipmentItemsTable = ({
  order,
  onSelectedLabelsCopy,
  onSelectedLabelsDelete,
  labels: allLabels,
  onLabelAdd: addLabel,
  onLabelUpdate,
  selectedLabels,
  onSelectedItemsChange,
  readonly,
}: Props) => {
  const menuItems = (isHeaderMenu?: boolean) => {
    const menuItems: AppMenuItem[] = [];
    if (isHeaderMenu) {
      menuItems.push({
        label: 'Add Label',
        faIcon: faPlus,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          addLabel(order.id!);
        },
      });
    }
    if (selectedLabels.length > 0) {
      menuItems.push({
        label: 'Copy',
        faIcon: faCopy,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          onSelectedLabelsCopy();
        },
      });

      menuItems.push({
        label: 'Delete',
        faIcon: faTimes,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          onSelectedLabelsDelete();
        },
      });
    }

    return menuItems;
  };

  const orderHeader = () => {
    return (
      <div className="p-d-flex w-100 p-mb-2">
        <div className="p-d-flex p-ai-center p-mr-4">
          <span>{order?.id ?? ''}</span>
        </div>
        <div className="p-d-flex p-ai-center p-mr-4">
          <span>{order?.reference ?? ''}</span>
        </div>
        <div className="p-d-flex p-ai-center p-mr-4">
          <span>{order?.owner_company?.name}</span>
        </div>
        <div className="p-d-flex p-ai-center p-mr-4">
          <span>{`${(order?.shipping_address as Address)?.street ?? ''} ${
            (order?.shipping_address as Address)?.state ?? ''
          }`}</span>
        </div>
      </div>
    );
  };

  const onCellInputChange = (
    value: string | number,
    label: ShipmentItem,
    name?: FieldName,
    dataSource: 'shipment_item' | 'dimension' | 'content_detail' = 'shipment_item'
  ) => {
    const updatedLabel: Partial<ShipmentItem> = {};
    if (dataSource === 'dimension') {
      updatedLabel.dimensions = {...label.dimensions, [name as keyof ShipmentItemDimension]: value as number};
    } else if (dataSource === 'content_detail') {
      updatedLabel.content_detail = {
        ...label.content_detail,
        [name as keyof ShipmentItemContentDetail]: value as string,
      };
    } else if (dataSource === 'shipment_item') {
      updatedLabel[name as keyof ShipmentItem] = value as unknown as any;
    }
    onLabelUpdate(order.id!, label.id!, updatedLabel);
  };

  const editCellTemplate = (
    shipmentItem: ShipmentItem,
    options: ColumnBodyOptions,
    type: 'string' | 'number',
    name: FieldName,
    dataSource: 'shipment_item' | 'dimension' | 'content_detail' = 'shipment_item'
  ) => {
    const value =
      dataSource === 'shipment_item'
        ? shipmentItem[name as keyof ShipmentItem]
        : dataSource === 'dimension'
          ? shipmentItem.dimensions[name as keyof ShipmentItemDimension]
          : dataSource === 'content_detail'
            ? shipmentItem.content_detail?.[name as keyof ShipmentItemContentDetail]
            : '';

    if (type === 'number') {
      return (
        <InputNumber
          className="w-100"
          inputClassName="w-100"
          value={value as number}
          onValueChange={e => onCellInputChange(e.target.value as number, shipmentItem, name, dataSource)}
          mode="decimal"
          inputMode="decimal"
          minFractionDigits={0}
          maxFractionDigits={4}
          min={0}
          locale="en-AU"
          disabled={readonly}
        />
      );
    }
    return (
      <InputText
        className="w-100"
        type={type}
        value={value as string}
        onChange={e => onCellInputChange(e.target.value, shipmentItem, name, dataSource)}
        disabled={readonly}
      />
    );
  };

  const editExtlabelCellTemplate = (shipmentItem: ShipmentItem, columnBodyOptions: ColumnBodyOptions) => {
    const options: string[] = [];
    if (order?.factory_order?.product_line === 'Colourvue') {
      options.push(...colourvueExtLabels);
    } else if (order?.factory_order?.product_line === 'Shadesol') {
      options.push(...shadesolExtLabels);
    } else if (order?.factory_order?.product_line === 'Curtains') {
      options.push(...curtainsExtLabels);
    }
    return (
      <Dropdown
        className={'w-100'}
        value={shipmentItem.ext_label}
        options={options}
        onChange={(e: DropdownChangeParams) => {
          // onLabelUpdate(itemUpdated);
          onCellInputChange(e.target.value, shipmentItem, 'ext_label', 'shipment_item');
        }}
        disabled={readonly}
      />
    );
  };

  async function onItemSelect(item: ShipmentItem) {
    const items: ShipmentItem[] = [...(selectedLabels as ShipmentItem[])];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      onSelectedItemsChange(items);
    }
  }

  const idTemplate = (rowData: ShipmentItem) => {
    const id = rowData.id && rowData.id > 0 ? rowData.id : 'new';
    if (readonly) {
      return id;
    }
    return (
      <AppColumnMenuBodyTemplate
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={() => menuItems(false)}
        selectedItems={selectedLabels}
        handleChangeSelectedItems={() => onItemSelect(rowData)}
      >
        {`${id}`}
      </AppColumnMenuBodyTemplate>
    );
  };

  const isNewFormat = !!allLabels?.[0]?.content_detail;

  return (
    <>
      {orderHeader()}
      <TwoDataTable
        showPaging={false}
        pageSizeIdentifier={'shipment_item_list_page_container'}
        sizeIdentifiers={['filter-box']}
        selectionMode={!readonly ? 'multiple' : undefined}
        selectedItems={selectedLabels}
        handleChangeSelectedItems={items => onSelectedItemsChange(items as ShipmentItem[])}
        value={allLabels}
        totalRecords={allLabels.length}
        editMode={'cell'}
        className="p-datatable-sm"
        activeFilters={[]}
        initMenuItems={!readonly ? () => menuItems(true) : undefined}
      >
        <Column style={{maxWidth: '80px'}} key="id" field="id" header="Id" body={idTemplate} />
        <Column
          style={{maxWidth: '60px'}}
          key="index"
          field="index"
          header="Index"
          body={(shipmentItem, options) =>
            editCellTemplate(shipmentItem as ShipmentItem, options, 'number', 'index', 'shipment_item')
          }
        />
        <Column
          style={{maxWidth: '60px'}}
          key="total"
          field="total"
          header="Total"
          body={(shipmentItem, options) =>
            editCellTemplate(shipmentItem as ShipmentItem, options, 'number', 'count', 'shipment_item')
          }
        />
        {isNewFormat && (
          <Column
            key="item"
            field="item"
            header="Item"
            body={(shipmentItem, options) =>
              editCellTemplate(shipmentItem as ShipmentItem, options, 'string', 'order_item', 'content_detail')
            }
          />
        )}
        {isNewFormat && (
          <Column
            key="location"
            field="location"
            header="Location"
            body={(shipmentItem, options) =>
              editCellTemplate(shipmentItem as ShipmentItem, options, 'string', 'location', 'content_detail')
            }
          />
        )}
        {isNewFormat && (
          <Column
            key="wxd"
            field="wxd"
            header="W x D"
            body={(shipmentItem, options) =>
              editCellTemplate(shipmentItem as ShipmentItem, options, 'string', 'more_detail', 'content_detail')
            }
          />
        )}
        {!isNewFormat && (
          <Column
            key="content"
            field="content"
            header="Content"
            body={(shipmentItem, options) =>
              editCellTemplate(shipmentItem as ShipmentItem, options, 'string', 'content', 'shipment_item')
            }
          />
        )}
        <Column
          style={{maxWidth: '120px'}}
          key="width"
          field="dimensions.width"
          header="Width"
          body={(shipmentItem, options) =>
            editCellTemplate(shipmentItem as ShipmentItem, options, 'number', 'width', 'dimension')
          }
        />
        <Column
          style={{maxWidth: '120px'}}
          key="height"
          field="dimensions.height"
          header="Height"
          body={(shipmentItem, options) =>
            editCellTemplate(shipmentItem as ShipmentItem, options, 'number', 'height', 'dimension')
          }
        />
        <Column
          style={{maxWidth: '120px'}}
          key="depth"
          field="dimensions.depth"
          header="Depth"
          body={(shipmentItem, options) =>
            editCellTemplate(shipmentItem as ShipmentItem, options, 'number', 'depth', 'dimension')
          }
        />
        <Column
          style={{maxWidth: '120px'}}
          key="weight"
          field="dimensions.weight"
          header="Weight"
          body={(shipmentItem, options) =>
            editCellTemplate(shipmentItem as ShipmentItem, options, 'number', 'weight', 'dimension')
          }
        />
        <Column
          style={{maxWidth: '160px'}}
          key="ext_label"
          field="ext_label"
          header="FF2 Label"
          body={editExtlabelCellTemplate}
        />
      </TwoDataTable>
    </>
  );
};

export default ShipmentItemsTable;

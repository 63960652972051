import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter, StockTransfer, Vehicle} from 'two-core';

export class StockTransfersService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.stockTransfers ?? '';
  }

  async getStockTransfers(params: QueryParameter): Promise<ApiListResponse> {
    const currentFactoryId = localStorage.getItem('current factory');
    if (!params.filters) {
      params.filters = [];
    }
    params.filters?.push(
      JSON.stringify({
        orConditions: [
          {
            field: 'origin_factory_id',
            value: currentFactoryId,
          },
          {
            field: 'destination_factory_id',
            value: currentFactoryId,
          },
        ],
      })
    );
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async createStockTransfer(data: Partial<StockTransfer>) {
    return this.post(this.endpoint, data)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async updateStockTransfer(id: number, data: Partial<StockTransfer>) {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async deleteStockTransfer(id: number): Promise<StockTransfer> {
    return this.delete(`${this.endpoint}/${id}`)
      .then(data => {
        return Promise.resolve(data as StockTransfer);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

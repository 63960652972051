import React from 'react';
import {TwoDataTable} from 'two-app-ui';
import {InventoryItem} from 'two-core';
import {Column} from 'primereact/column';
import {toInputUppercase} from '../../Inventory/Constants/Utils';
import {InputText} from 'primereact/inputtext';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import {Card} from 'primereact/card';
import {InputNumber} from 'primereact/inputnumber';
import {InventoryItemFreeStockColumn} from '../../InventoryItem/InventoryItemFreeStockColumn';
import {InventoryItemIncomingColumn} from '../../InventoryItem/InventoryItemIncomingColumn';
import {IncomingPos, PurchaseOrderItemGroup} from '../../PurchaseOrder/PurchaseOrderItems';

export interface EditStockTransferInventoryItemListFilterProps {
  ids?: string[];
  name?: string;
  colour?: string;
  category?: string[];
  sku?: string;
}
interface Props {
  droppableId: string;
  items: InventoryItem[];
  totalItems?: number;
  patrolElement?: any;
  pageSizeIdentifier: string;
  filters?: EditStockTransferInventoryItemListFilterProps;
  onFilterChange?: (filters: Partial<EditStockTransferInventoryItemListFilterProps>) => void;
  loading?: boolean;
  quantityMap?: Map<InventoryItem['id'], number>;
  onQuantityChange?: (itemId: InventoryItem['id'], quantity: number) => void;
  readonly?: boolean;
  incomingPosMap?: Map<InventoryItem['id'], IncomingPos>;
  loadingIncomingPos?: boolean;
}

export const EditStockTransferItemList = ({
  items,
  droppableId,
  totalItems,
  patrolElement,
  pageSizeIdentifier,
  onFilterChange,
  filters,
  loading,
  quantityMap,
  onQuantityChange,
  readonly,
  incomingPosMap,
  loadingIncomingPos,
}: Props) => {
  const nameFilter = (
    <InputText
      value={filters?.name}
      className="form-filter"
      onChange={e => onFilterChange?.({name: e.target.value})}
      onInput={toInputUppercase}
    />
  );
  const skuFilter = (
    <InputText
      value={filters?.sku}
      className="form-filter"
      onChange={e => onFilterChange?.({sku: e.target.value})}
      onInput={toInputUppercase}
    />
  );
  const colourFilter = (
    <InputText
      value={filters?.colour}
      className="form-filter"
      onChange={e => onFilterChange?.({colour: e.target.value})}
      onInput={toInputUppercase}
    />
  );

  const renderClone = (provided: any, snapshot: any, rubric: any) => {
    const invItemId = rubric.draggableId.split('_')[0];
    const item = items.find(item => item.id === invItemId);
    return (
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <Card style={{width: '300px'}}>{item?.name ?? invItemId}</Card>
      </div>
    );
  };

  const itemBodyDraggableBody = (rowData: InventoryItem, key: string) => {
    const value = `${rowData[key as keyof InventoryItem]}`;

    if (patrolElement && rowData.id === 'patrol') {
      return patrolElement;
    }

    if (readonly) {
      return value;
    }

    return (
      <React.Fragment>
        <Draggable
          key={`${rowData.id}_${key}`}
          draggableId={`${rowData.id}_${key}`}
          index={0}
          disableInteractiveElementBlocking={true}
        >
          {provided => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{transform: 'none'}}
              >
                {value}
              </div>
            );
          }}
        </Draggable>
      </React.Fragment>
    );
  };

  const quantityBody = (rowData: InventoryItem, quantity: number) => {
    if (onQuantityChange && !readonly) {
      return (
        <InputNumber
          inputClassName="w-100"
          value={quantity}
          onChange={e => onQuantityChange(rowData.id!, e.value ?? 0)}
          onBlur={e => onQuantityChange(rowData.id!, e.target.value ? +e.target.value : 0)}
          step={0.01}
          maxFractionDigits={2}
          minFractionDigits={0}
          mode="decimal"
        />
      );
    }
    return quantity;
  };

  return (
    <Droppable droppableId={droppableId ?? ''} renderClone={renderClone}>
      {provided => (
        <div ref={provided.innerRef}>
          <TwoDataTable
            pageSizeIdentifier={pageSizeIdentifier}
            selectedItems={[]}
            activeFilters={[]}
            value={items}
            hideFilter={!onFilterChange}
            showPaging={false}
            totalRecords={totalItems}
            loading={loading}
          >
            <Column
              header="Name"
              field="name"
              body={rowData => itemBodyDraggableBody(rowData, 'name')}
              filter
              filterElement={nameFilter}
              showFilterMenu={false}
              className="col-min-xxl"
            />
            <Column
              header="Colour"
              field="colour"
              body={rowData => itemBodyDraggableBody(rowData, 'colour')}
              filter
              filterElement={colourFilter}
              showFilterMenu={false}
              className="col-min-l"
            />
            <Column
              header="SKU"
              field="sku"
              body={rowData => itemBodyDraggableBody(rowData, 'sku')}
              filter
              filterElement={skuFilter}
              showFilterMenu={false}
              className="col-min-m col-max-xxl"
            />
            <Column
              header="UoM"
              field="uom"
              body={rowData => itemBodyDraggableBody(rowData, 'uom')}
              className="col-m"
            />
            <Column
              header="Free Stock"
              body={(item: InventoryItem) => <InventoryItemFreeStockColumn item={item} />}
              className="col-max-m"
              hidden={!!quantityMap}
            />
            <Column
              header="Incoming"
              body={(item: InventoryItem) => (
                <InventoryItemIncomingColumn
                  item={item}
                  incomingPos={incomingPosMap?.get(item.id)}
                  loadingIncomingPos={loadingIncomingPos}
                />
              )}
              className="col-max-s"
              hidden={!!quantityMap}
            />
            <Column
              header="QTY"
              body={rowData => quantityBody(rowData, quantityMap?.get(rowData.id!) ?? 0)}
              className="col-m"
              hidden={!quantityMap}
            />
          </TwoDataTable>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

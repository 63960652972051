import React from 'react';
import {Tooltip} from 'primereact/tooltip';
import {InventoryItem} from 'two-core';
import {OrderStagePurchaseOrdersTooltipContent} from '../Order/OrderStagePurchaseOrdersTooltipContent';
import {IncomingPos} from '../PurchaseOrder/PurchaseOrderItems';
interface Props {
  item: InventoryItem;
  loadingIncomingPos?: boolean;
  incomingPos?: IncomingPos;
}
export const InventoryItemIncomingColumn = ({item, incomingPos, loadingIncomingPos}: Props) => {
  const id = `inventory_item_incoming_qty_${item.id}`;
  return (
    <>
      <Tooltip target={`#${id}`} showDelay={500} style={{minWidth: '250px'}} position={'left'}>
        <OrderStagePurchaseOrdersTooltipContent loading={loadingIncomingPos} purchaseOrders={incomingPos?.pos} />
      </Tooltip>
      <span id={id}>{Math.round((incomingPos?.qty ?? 0) * 100) / 100}</span>
    </>
  );
};

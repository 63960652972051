import React from 'react';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  TwoDataTable,
  TwoToast,
} from 'two-app-ui';
import {
  Bom,
  InventoryItem,
  InventoryItemAggregate,
  PurchaseOrder,
  PurchaseOrderItem,
  QueryParameter,
  Supplier,
  SupplyItem,
} from 'two-core';
import {Column} from 'primereact/column';
import {MenuItemOptions} from 'primereact/menuitem';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import PurchaseOrderItemAddDialog from '../PurchaseOrderItems/PurchaseOrderItemAddDialog';
import {NavLink} from 'react-router-dom';
import SupplyItemsService from '../../services/SupplyItemsService';
import {faBoxesStacked, faCoins, faPlusCircle, faTimes} from '@fortawesome/pro-regular-svg-icons';
import {PurchaseOrderSupplyItems} from './PurchaseOrderSupplyItems';
import {PurchaseOrderItemAddSupplyItemDialog} from '../PurchaseOrderItems/PurchaseOrderItemAddSupplyItemDialog';
import InventoryService from '../../services/InventoryService';
import {DataTableExpandedRows, DataTablePFSEvent, DataTableSortOrderType} from 'primereact/datatable';
import {Tooltip} from 'primereact/tooltip';
import {OrderStagePurchaseOrdersTooltipContent} from '../Order/OrderStagePurchaseOrdersTooltipContent';
import {Divider} from 'primereact/divider';
import {getFreeStock} from '../../utils/InventoryItemUtil';
import {InventoryItemFreeStockColumn} from '../InventoryItem/InventoryItemFreeStockColumn';
import {InventoryItemIncomingColumn} from '../InventoryItem/InventoryItemIncomingColumn';

export interface PurchaseOrderItemGroup {
  inventoryItem: InventoryItem;
  purchaseOrderItems: PurchaseOrderItem[];
  incomingPos?: IncomingPos;
  relatedBoms?: RelatedBoms;
}

export interface IncomingPos {
  qty: number;
  pos: PurchaseOrder[];
}

interface RelatedBoms {
  qtySum: number;
  boms: Bom[];
}

export interface InvalidInventoryItem {
  inventoryItem: InventoryItem;
  reason: string;
}

interface Props {
  purchaseOrder: PurchaseOrder;
  supplier: Supplier;
  editable?: boolean;
  itemsTableId: string;
  onItemsChange?: (newPoItems: PurchaseOrderItem[]) => void;
  onValidated?: (isValid: boolean, invalidItems: InvalidInventoryItem[]) => void;
}

interface State {
  poItemGroups: PurchaseOrderItemGroup[];
  poItems: PurchaseOrderItem[];
  loading: boolean;
  showAddDialog: boolean;
  showEditDialog: boolean;
  showPoAddSupplyItemDialog: boolean;
  selectedGroup?: PurchaseOrderItemGroup;
  expandedRows: DataTableExpandedRows;
  sortBy?: {
    field: string;
    order: DataTableSortOrderType;
  };
}

class PurchaseOrderItems extends React.Component<Props, State> {
  static contextType = AppContext;
  purchaseOrdersService?: PurchaseOrdersService;
  supplyItemsService?: SupplyItemsService;
  inventoryService?: InventoryService;
  twoToast?: TwoToast;
  constructor(props: Props) {
    super(props);
    this.state = {
      poItems: [],
      poItemGroups: [],
      loading: false,
      showAddDialog: false,
      showEditDialog: false,
      showPoAddSupplyItemDialog: false,
      expandedRows: {},
      sortBy: {
        field: 'inventoryItem.name',
        order: 1,
      },
    };

    this.initMenuItems = this.initMenuItems.bind(this);
    this.hideEditDialog = this.hideEditDialog.bind(this);
    this.onHideAddDialog = this.onHideAddDialog.bind(this);
    this.itemNameTemplate = this.itemNameTemplate.bind(this);
    this.onQuantityChange = this.onQuantityChange.bind(this);
    this.addPoItems = this.addPoItems.bind(this);
    this.onAddSupplyItemClick = this.onAddSupplyItemClick.bind(this);
    this.onPoAddSupplyItemDialHide = this.onPoAddSupplyItemDialHide.bind(this);
    this.onPoAddSupplyItemDialSave = this.onPoAddSupplyItemDialSave.bind(this);
    this.onGroupRemove = this.onGroupRemove.bind(this);
    this.onPoItemRemove = this.onPoItemRemove.bind(this);
    this.onMakeDefault = this.onMakeDefault.bind(this);
    this.onSort = this.onSort.bind(this);
    this.orderingBody = this.orderingBody.bind(this);
    this.freeStockBody = this.freeStockBody.bind(this);
    this.incomingBody = this.incomingBody.bind(this);
    this.validate = this.validate.bind(this);
  }

  async componentDidMount() {
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.supplyItemsService = this.context.supplyItemsService;
    this.inventoryService = this.context.inventoryService;
    this.twoToast = this.context.twoToast;

    this.initiateData();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps !== this.props && prevProps.purchaseOrder !== this.props.purchaseOrder) {
      this.initiateData();
    }
  }

  async initiateData() {
    this.setState({
      poItemGroups: [],
      loading: true,
    });
    try {
      //if po has items already use them, if not use its id to load them
      const poItems = this.props.purchaseOrder.items ?? [];
      if (poItems.length === 0 && this.props.purchaseOrder.id) {
        const poParams = {
          aggregate: ['items'],
          filters: [
            JSON.stringify({
              field: 'id',
              value: this.props.purchaseOrder.id,
            }),
          ],
        } as QueryParameter;
        const pos = await this.purchaseOrdersService?.getPurchaseOrders(poParams);
        if (!pos || !pos.records || (pos.records as PurchaseOrder[]).length === 0) {
          this.setState({
            poItemGroups: [],
          });
          return;
        }
        const po = (pos.records as PurchaseOrder[])[0];
        poItems.push(...(po.items ?? []));
      }
      if (this.props.editable && this.props.purchaseOrder.stage === 'Draft') {
        this.loadMissingPoItems(this.props.purchaseOrder, poItems).then(missingItems => {
          const allItems = [...poItems, ...missingItems];
          this.generateGroups(allItems).then(groups => {
            this.setState({poItemGroups: this.sortGroups(groups), loading: false}, () => {
              if (this.props.onItemsChange) {
                this.props.onItemsChange(this.extractPurchaseOrderItems());
              }
              this.validate();
            });
          });
        });
      } else {
        this.generateGroups(poItems).then(groups => {
          this.setState({poItemGroups: this.sortGroups(groups), loading: false}, () => {
            if (this.props.onItemsChange) {
              this.props.onItemsChange(this.extractPurchaseOrderItems());
            }
            this.validate();
          });
        });
      }
    } catch (e) {
      console.error('Error initiating item groups:', e);
      this.twoToast?.showError(
        'Uups, we hit an obstacle initiating the Purchase Order items. Please, refresh and try again.'
      );
      this.setState({loading: false});
    }
  }

  async generateGroups(poItems: PurchaseOrderItem[]) {
    const {purchaseOrder, supplier} = this.props;

    const unidsset = new Set(poItems.map(poItem => poItem.inventory_item_id));
    const uniqueInventoryItemIds = Array.from(unidsset);

    const iiWithSupplyItems = await this.loadSupplyItems(uniqueInventoryItemIds, supplier!.id);
    const relatedBoms = await this.loadRelatedBoms(purchaseOrder!);
    const incomingPos = await this.loadIncomingPos(uniqueInventoryItemIds);
    const groups: PurchaseOrderItemGroup[] = [];

    for (const inventoryItemId of uniqueInventoryItemIds) {
      const inventoryItem = iiWithSupplyItems.find(ii => ii.id === inventoryItemId);
      const poItemsForInventoryItem = poItems.filter(poItem => poItem.inventory_item_id === inventoryItemId);
      const incomingPosForItem = incomingPos.get(inventoryItemId);
      const relatedBomsForItem = relatedBoms.filter(bom => bom.inventory_item_id === inventoryItemId);
      for (const poItem of poItemsForInventoryItem) {
        if (!poItem.supply_item) {
          poItem.supply_item = inventoryItem?.supply_items?.find(supplyItem => supplyItem.id === poItem.supply_item_id);
        }
      }
      const group: PurchaseOrderItemGroup = {
        inventoryItem: inventoryItem!,
        purchaseOrderItems: poItemsForInventoryItem,
        incomingPos: incomingPosForItem,
        relatedBoms: {
          qtySum: relatedBomsForItem.reduce((acc, bom) => acc + bom.quantity, 0),
          boms: relatedBomsForItem,
        },
      };
      groups.push(group);
    }
    return groups;
  }

  sortGroups(groups2sort: PurchaseOrderItemGroup[]) {
    const sortBy = this.state.sortBy?.field ?? 'inventoryItem.name';
    const sortOrder = this.state.sortBy?.order ?? 1;

    const sortColumnName = (sortBy.split('.')[1] ?? sortBy) as keyof InventoryItem;
    groups2sort.sort((a, b) => {
      if ((a.inventoryItem[sortColumnName] ?? '') > (b.inventoryItem[sortColumnName] ?? '')) {
        return sortOrder;
      }
      if ((a.inventoryItem[sortColumnName] ?? '') < (b.inventoryItem[sortColumnName] ?? '')) {
        return -sortOrder;
      }
      return 0;
    });

    return groups2sort;
  }

  async loadMissingPoItems(purchaseOrder: PurchaseOrder, poItems: PurchaseOrderItem[]) {
    try {
      const result = await this.purchaseOrdersService?.generateMissingPurchaseOrderItems(purchaseOrder.id!);
      const missingPoItems = (result ?? []) as PurchaseOrderItem[];

      const filters = [
        JSON.stringify({
          field: 'id',
          value: missingPoItems.map(missingPoItem => missingPoItem.inventory_item_id),
          condition: 'in',
        }),
      ];
      const aggregate: InventoryItemAggregate[] = ['supply_items'];
      const inventoryItemsResult = await this.inventoryService?.getInventoryItems({
        filters: filters,
        aggregate: aggregate,
      });
      const inventoryItems = (inventoryItemsResult?.records ?? []) as InventoryItem[];
      let temporaryId = Math.min(...(poItems?.map(poItem => poItem.id!) ?? []), 0) - 1;
      for (const missingPoItem of missingPoItems) {
        missingPoItem.id = temporaryId--;
        const inventoryItem = inventoryItems.find(
          inventoryItem => inventoryItem.id === missingPoItem.inventory_item_id
        );
        missingPoItem.inventory_item = inventoryItem;
        missingPoItem.supply_item = inventoryItem?.supply_items?.find(
          supplyItem => supplyItem.id === missingPoItem.supply_item_id
        );
      }
      return missingPoItems;
    } catch (e) {
      return [];
    }
  }

  async loadSupplyItems(inventoryItemIds: string[], supplierId?: string) {
    const result: InventoryItem[] = [];
    const invItemFetches = [];
    const ids = [...inventoryItemIds];
    while (ids.length > 0) {
      const chunkedIds = ids.splice(0, 25);
      const invItemParams = {
        aggregate: ['supply_items'],
        filters: [
          JSON.stringify({
            field: 'id',
            value: chunkedIds,
            condition: 'in',
          }),
          JSON.stringify({
            field: 'supply_item.supplier_id',
            value: supplierId,
          }),
        ],
      } as QueryParameter;
      invItemFetches.push(
        this.inventoryService?.getInventoryItems(invItemParams).then(response => {
          const records = (response?.records as InventoryItem[]) ?? [];
          result.push(...records);
        })
      );
    }
    await Promise.all(invItemFetches);
    return result;
  }

  async loadRelatedBoms(forPo: PurchaseOrder): Promise<Bom[]> {
    let result: Bom[] = [];
    if (!forPo.id) {
      return result;
    }
    const poAggregateFilter = {
      aggregate: ['related_boms'],
      filters: [
        JSON.stringify({
          field: 'id',
          value: forPo.id,
        }),
      ],
    } as QueryParameter;
    const poAggregate = await this.purchaseOrdersService?.getPurchaseOrders(poAggregateFilter);
    if (poAggregate?.records && (poAggregate.records as PurchaseOrder[]).length > 0) {
      result = (poAggregate.records as PurchaseOrder[])[0].related_boms ?? [];
    }
    return result;
  }

  async loadIncomingPos(inventoryItemIds: string[]): Promise<Map<string, IncomingPos>> {
    const result = new Map<string, IncomingPos>();
    if (!this.props.purchaseOrder?.id) {
      return result;
    }
    const chunkedIds = [];
    const pos: PurchaseOrder[] = [];
    const ids = [...inventoryItemIds];
    while (ids.length > 0) {
      chunkedIds.push(ids.splice(0, 25));
    }
    const poFetches = [];
    for (const chunk of chunkedIds) {
      const poParams = {
        filters: [
          JSON.stringify({
            field: 'id',
            value: this.props.purchaseOrder?.id,
            condition: '<>',
          }),
          JSON.stringify({
            field: 'purchase_order_item.inventory_item_id',
            value: chunk,
            condition: 'in',
          }),
          JSON.stringify({
            field: 'stage',
            value: ['Ordered', 'Eta Confirmed', 'Delayed'],
            condition: 'in',
          }),
        ],
        aggregate: ['items'],
      } as QueryParameter;
      poFetches.push(
        this.purchaseOrdersService?.getPurchaseOrders(poParams).then(response => {
          const posChunk = (response?.records as PurchaseOrder[]) ?? [];
          pos.push(...posChunk);
        })
      );
    }
    await Promise.all(poFetches);
    for (const po of pos) {
      for (const poItem of po.items ?? []) {
        if (inventoryItemIds.includes(poItem.inventory_item_id)) {
          const incoming4item = result.get(poItem.inventory_item_id) ?? {
            qty: 0,
            pos: [],
          };
          incoming4item.qty += poItem.qty_in_uom ?? 0;
          if (!incoming4item.pos.find(existingPo => existingPo.id === po.id)) {
            incoming4item.pos.push(po);
          }
          result.set(poItem.inventory_item_id, incoming4item);
        }
      }
    }
    return result;
  }

  async onMakeDefault(poItem: PurchaseOrderItem) {
    if (!poItem.supply_item?.default_option) {
      const poItemGroup = this.state.poItemGroups.find(group => group.inventoryItem.id === poItem.inventory_item_id);
      for (const supplyItem of poItemGroup?.inventoryItem.supply_items ?? []) {
        let defaultOption = false;
        if (supplyItem.id === poItem.supply_item_id) {
          defaultOption = true;
        }
        await this.inventoryService?.updateSupplyItem(poItem.inventory_item_id, supplyItem.id!, {
          ...supplyItem,
          default_option: defaultOption,
        });
      }
    }
  }

  validate() {
    let valid = true;
    const invalidItems: InvalidInventoryItem[] = [];
    for (const group of this.state.poItemGroups) {
      const orderQtyInUom = group.purchaseOrderItems.reduce((acc, poItem) => acc + (poItem.qty_in_uom ?? 0), 0);
      const bomsNotCovered = (group.relatedBoms?.qtySum ?? 0) > orderQtyInUom;
      const allStock =
        (group.inventoryItem.current_stock_level ?? 0) -
        (group.inventoryItem.reserved_qty ?? 0) -
        (group.inventoryItem.reorder_limit ?? 0) +
        (group.incomingPos?.qty ?? 0);
      const stockNotCovered = -allStock > orderQtyInUom;
      if (bomsNotCovered || stockNotCovered) {
        let reason = bomsNotCovered
          ? ` BoMs not covered (req: ${Math.round((group.relatedBoms?.qtySum ?? 1) * 100) / 100} > ordered: ${Math.round(orderQtyInUom * 100) / 100})`
          : '';
        reason += stockNotCovered
          ? ` Stock gap not covered (gap: ${Math.round(allStock * 100) / 100} < ordered: ${Math.round(orderQtyInUom * 100) / 100})`
          : '';
        valid = false;
        invalidItems.push({
          inventoryItem: group.inventoryItem,
          reason: reason,
        });
      }
    }
    if (this.props.onValidated) {
      this.props.onValidated(valid, invalidItems);
    }
  }

  extractPurchaseOrderItems(): PurchaseOrderItem[] {
    const result: PurchaseOrderItem[] = this.state.poItemGroups.map(group => group.purchaseOrderItems).flat();
    return result;
  }

  initHeaderMenuItems() {
    const {purchaseOrder} = this.props;
    const menuItems: AppMenuItem[] = [];
    if (!purchaseOrder || purchaseOrder.stage === 'Draft') {
      menuItems.push({
        label: 'Add Items',
        faIcon: faPlusCircle,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => this.showAddDialog(),
      });
    }
    return menuItems;
  }

  createNewPoItem(supplyItem: SupplyItem, quantity2coverInUom: number) {
    const orderQty =
      Math.round(
        Math.max(quantity2coverInUom / (supplyItem.reorder_qty_in_uom ?? 1), supplyItem.order_minimum ?? 0) * 100
      ) / 100;
    const packageSize = Math.max(supplyItem.package_size ?? 0.01, 0.01);
    const orderQtyInPackageSize = Math.ceil(orderQty / packageSize) * packageSize;
    const detail = `${supplyItem.sku}: ${supplyItem.name}`;
    const poItem: PurchaseOrderItem = {
      purchase_order_id: '',
      supply_item_id: supplyItem.id!,
      detail: detail,
      inventory_item_id: supplyItem.inventory_item_id,
      order_unit: supplyItem.reorder_unit,
      unit_price: supplyItem.unit_price ?? 0,
      package_size: supplyItem.package_size_label ?? '',
      quantity: orderQtyInPackageSize,
      qty_in_uom: orderQtyInPackageSize * (supplyItem.reorder_qty_in_uom ?? 1),
      delivered_qty: 0,
      supply_item: supplyItem,
    };
    return poItem;
  }

  calcNewItemQty(forGroup: PurchaseOrderItemGroup) {
    const uncoveredBomQtyInUom =
      (forGroup.relatedBoms?.qtySum ?? 0) -
      forGroup.purchaseOrderItems.reduce((acc, poItem) => acc + (poItem.qty_in_uom ?? 0), 0);
    const freeCapacity =
      (forGroup.inventoryItem.current_stock_level ?? 0) -
      (forGroup.inventoryItem.reserved_qty ?? 0) -
      (forGroup.inventoryItem.reorder_limit ?? 0) +
      (forGroup.incomingPos?.qty ?? 0);
    return Math.max(uncoveredBomQtyInUom, -freeCapacity);
  }

  async onAddSupplyItemClick(poItemGroup: PurchaseOrderItemGroup) {
    if ((poItemGroup.inventoryItem.supply_items?.length ?? 0) - poItemGroup.purchaseOrderItems.length === 1) {
      this.setState({loading: true});
      const capacity2cover = this.calcNewItemQty(poItemGroup);
      const unusedSupplyItem = poItemGroup.inventoryItem.supply_items?.find(
        supi => !poItemGroup.purchaseOrderItems.find(poItem => poItem.supply_item_id === supi.id)
      );
      const newPoItem = this.createNewPoItem(unusedSupplyItem!, capacity2cover);
      poItemGroup.purchaseOrderItems.push(newPoItem);
      if (this.props.onItemsChange) {
        this.props.onItemsChange(this.extractPurchaseOrderItems());
      }
      if (poItemGroup.inventoryItem.id) {
        const expRows = {...this.state.expandedRows};
        expRows[poItemGroup.inventoryItem.id] = !expRows[poItemGroup.inventoryItem.id];
        this.setState({expandedRows: expRows, loading: false}, () => {
          if (this.props.onItemsChange) {
            this.props.onItemsChange(this.extractPurchaseOrderItems());
          }
          this.validate();
        });
      }
    } else {
      this.setState({showPoAddSupplyItemDialog: true, selectedGroup: poItemGroup});
    }
  }

  onGroupRemove(group2remove: PurchaseOrderItemGroup) {
    const newPoItemGroups = this.state.poItemGroups.filter(group => group !== group2remove);
    this.setState({poItemGroups: newPoItemGroups}, () => {
      if (this.props.onItemsChange) {
        this.props.onItemsChange(this.extractPurchaseOrderItems());
      }
      this.validate();
    });
  }

  onPoItemRemove(poItem2remove: PurchaseOrderItem) {
    const group = this.state.poItemGroups.find(group =>
      group.purchaseOrderItems.find(poItem => poItem.id === poItem2remove.id)
    );
    if (group) {
      if (group.purchaseOrderItems.length === 1) {
        this.onGroupRemove(group);
      } else {
        const newPoItems = group.purchaseOrderItems.filter(poItem => poItem !== poItem2remove);
        group.purchaseOrderItems = newPoItems;
        if (this.props.onItemsChange) {
          this.props.onItemsChange(this.extractPurchaseOrderItems());
        }
        this.validate();
      }
    } else {
      this.twoToast?.showError('Uups, we failed to remove the selected item. Please, refresh and try again.');
    }
  }

  async addPoItems(newPurchaseOrderItems: PurchaseOrderItem[]) {
    this.setState({loading: true});
    this.generateGroups(newPurchaseOrderItems)
      .then(groups => {
        const newGroups = [...this.state.poItemGroups, ...groups];
        this.setState({poItemGroups: this.sortGroups(newGroups), loading: false}, () => {
          if (this.props.onItemsChange) {
            this.props.onItemsChange(this.extractPurchaseOrderItems());
          }
          this.validate();
        });
      })
      .catch(e => {
        console.error('Error generating groups:', e);
        this.twoToast?.showError(
          'Uups, we hit an obstacle initiating the Purchase Order items. Please, refresh and try again.'
        );
        this.setState({loading: false});
      });
  }

  onQuantityChange(quantity: number, poItem: PurchaseOrderItem) {
    const {onItemsChange} = this.props;
    const {poItemGroups} = this.state;
    let qtyInUomRatio = 1;
    if (poItem.supply_item && poItem.supply_item.reorder_qty_in_uom) {
      qtyInUomRatio = poItem.supply_item.reorder_qty_in_uom;
    } else {
      const itemsGroup = this.state.poItemGroups.find(group => group.inventoryItem.id === poItem.inventory_item_id);
      if (itemsGroup) {
        const relatedSupplyItem = itemsGroup.inventoryItem.supply_items?.find(
          supi => supi.id === poItem.supply_item_id
        );
        qtyInUomRatio = relatedSupplyItem?.reorder_qty_in_uom ?? 1;
      }
    }
    const qtyInUom = Math.round(quantity * qtyInUomRatio * 100) / 100;
    const updatedPoItemGroups = poItemGroups.map(group => {
      const updatedItems = group.purchaseOrderItems.map(item => {
        if (item.id === poItem.id) {
          return {...item, quantity: quantity, qty_in_uom: qtyInUom};
        }
        return item;
      });
      return {...group, purchaseOrderItems: updatedItems};
    });
    this.setState({poItemGroups: updatedPoItemGroups}, () => {
      onItemsChange?.(this.extractPurchaseOrderItems());
      this.validate();
    });
  }

  onPoAddSupplyItemDialSave(selectedSupplyItem: SupplyItem) {
    if (this.state.selectedGroup) {
      const capacity2cover = this.calcNewItemQty(this.state.selectedGroup);
      this.state.selectedGroup.purchaseOrderItems.push(this.createNewPoItem(selectedSupplyItem, capacity2cover));
      const expRows = {...this.state.expandedRows};
      if (this.state.selectedGroup.inventoryItem.id) {
        expRows[this.state.selectedGroup.inventoryItem.id] = !expRows[this.state.selectedGroup.inventoryItem.id];
      }
      this.setState({
        showPoAddSupplyItemDialog: false,
        selectedGroup: undefined,
        expandedRows: expRows,
      });
      if (this.props.onItemsChange) {
        this.props.onItemsChange(this.extractPurchaseOrderItems());
      }
      this.validate();
    } else {
      this.setState({showPoAddSupplyItemDialog: false, selectedGroup: undefined});
      this.twoToast?.showError('Uups, we failed to add the selected Supply Item. Please, try again.');
    }
  }

  initMenuItems(poItemGroup: PurchaseOrderItemGroup): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    if (!this.props.purchaseOrder || this.props.purchaseOrder.stage === 'Draft' || this.props.editable) {
      if (poItemGroup.purchaseOrderItems.length < (poItemGroup.inventoryItem.supply_items?.length ?? 0)) {
        menuItems.push({
          label: 'Add Supply Item',
          faIcon: faPlusCircle,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.onAddSupplyItemClick(poItemGroup);
          },
        });
      }
      // menuItems.push({
      //   label: 'Calc to min Price',
      //   faIcon: faCoins,
      //   template: (item: AppMenuItem, options: MenuItemOptions) => {
      //     return <AppMenuItemTemplate item={item} options={options} />;
      //   },
      //   command: () => {
      //     //todo TBD
      //   },
      // });
      // menuItems.push({
      //   label: 'Calc to min Qty',
      //   faIcon: faBoxesStacked,
      //   template: (item: AppMenuItem, options: MenuItemOptions) => {
      //     return <AppMenuItemTemplate item={item} options={options} />;
      //   },
      //   command: () => {
      //     //todo TBD
      //   },
      // });
      menuItems.push({
        label: 'Remove',
        faIcon: faTimes,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.onGroupRemove(poItemGroup);
        },
      });
    }

    return menuItems;
  }

  showAddDialog() {
    this.setState({showAddDialog: true});
  }

  onHideAddDialog() {
    this.setState({showAddDialog: false});
  }

  showEditDialog() {
    this.setState({showEditDialog: true});
  }

  hideEditDialog() {
    this.setState({showEditDialog: false});
  }

  onPoAddSupplyItemDialHide() {
    this.setState({showPoAddSupplyItemDialog: false, selectedGroup: undefined});
  }

  rowExpansionTemplate = (group: PurchaseOrderItemGroup) => {
    return (
      <PurchaseOrderSupplyItems
        purchaseOrderItems={group.purchaseOrderItems}
        purchaseOrder={this.props.purchaseOrder}
        readonly={!this.props.editable}
        onPoItemRemove={this.onPoItemRemove}
        onMakeDefault={this.onMakeDefault}
        onQuantityChange={this.onQuantityChange}
      />
    );
  };

  itemNameTemplate(group: PurchaseOrderItemGroup) {
    const {editable} = this.props;
    const hasNewPoItems = group.purchaseOrderItems.some(poItem => poItem.id! <= 0);
    const newClass = hasNewPoItems ? 'new-supply-item' : '';
    const rowBody = (
      <NavLink to={'/inventory-item/' + group.inventoryItem.id}>
        <span className={newClass}>{group.inventoryItem!.name}</span>
      </NavLink>
    );
    if (!editable) {
      return rowBody;
    }
    return (
      <AppColumnMenuBodyTemplate
        isDynamicMenuItems={true}
        initMenuItems={() => this.initMenuItems(group)}
        rowItemIdentifier={group.inventoryItem.id?.toString() ?? ''}
        selectedItems={[]}
      >
        {rowBody}
      </AppColumnMenuBodyTemplate>
    );
  }
  freeStockBody(group: PurchaseOrderItemGroup) {
    return <InventoryItemFreeStockColumn item={group.inventoryItem} />;
  }

  incomingBody(group: PurchaseOrderItemGroup) {
    return <InventoryItemIncomingColumn item={group.inventoryItem} incomingPos={group.incomingPos} />;
  }

  orderingBody(group: PurchaseOrderItemGroup) {
    const invItem = group.inventoryItem;

    const orderingQty = group.purchaseOrderItems.reduce((acc, poItem) => {
      return acc + (poItem.qty_in_uom ?? 0);
    }, 0);
    const invItemsBomsQtySum = group.relatedBoms?.qtySum ?? 0;
    //sum free stock and incoming qty
    const freeStockAndIncomingQty = -(getFreeStock(invItem) + (group.incomingPos?.qty ?? 0));
    const validQty = Math.max(freeStockAndIncomingQty, invItemsBomsQtySum);

    let qtyClass = '';
    let tooltip = undefined;
    const id = `inventory_item_ordering_${invItem.id}`;
    tooltip = (
      <Tooltip target={`#${id}`} position="left">
        <div style={{width: '300px'}}>
          <div className="p-grid">
            <label className="p-col-12 p-md-8">assigned orders require</label>
            <div className="p-col-12 p-md-4">
              <div className={orderingQty < invItemsBomsQtySum ? 'p-error p-text-bold' : ''}>
                {Math.round(invItemsBomsQtySum * 100) / 100}
              </div>
            </div>
          </div>
          <Divider />
          <div className="p-grid">
            <label className="p-col-12 p-md-8">free stock + incoming</label>
            <div className="p-col-12 p-md-4">
              <div className={orderingQty < freeStockAndIncomingQty ? 'p-error p-text-bold' : ''}>
                {Math.round(-freeStockAndIncomingQty * 100) / 100}
              </div>
            </div>
          </div>
        </div>
      </Tooltip>
    );
    if (orderingQty < validQty) {
      qtyClass = 'p-error';
    }

    return (
      <>
        {tooltip}
        <span id={id} className={qtyClass}>
          {Math.round(orderingQty * 100) / 100}
        </span>
      </>
    );
  }

  onSort(e: DataTablePFSEvent) {
    this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
  }

  render() {
    const {poItemGroups, loading, showAddDialog, selectedGroup, showPoAddSupplyItemDialog, sortBy} = this.state;
    const {purchaseOrder, editable, itemsTableId, supplier} = this.props;

    let initMenuItemsFunction = undefined;
    if ((!purchaseOrder || purchaseOrder.stage === 'Draft') && editable) {
      initMenuItemsFunction = () => this.initHeaderMenuItems();
    }

    return (
      <div id={itemsTableId} className="page-container">
        <TwoDataTable
          pageSizeIdentifier={itemsTableId}
          selectedItems={[]}
          loading={loading}
          value={poItemGroups}
          activeFilters={{}}
          initMenuItems={initMenuItemsFunction}
          isMenuDynamic
          showPaging={false}
          dataKey="inventoryItem.id"
          rowExpansionTemplate={this.rowExpansionTemplate}
          expandedRows={this.state.expandedRows}
          onRowToggle={e => {
            this.setState({expandedRows: e.data as unknown as DataTableExpandedRows});
          }}
          hideFilter={true}
          sortField={sortBy?.field}
          sortOrder={sortBy?.order}
          onSort={this.onSort}
        >
          <Column expander className={'table-expander'} bodyClassName={'table-expander'} />
          <Column
            header="Name"
            body={this.itemNameTemplate}
            field="inventoryItem.name"
            sortable
            className="col-min-xl"
          />
          <Column header="Colour" field="inventoryItem.colour" className="col-min-l" />
          <Column header="Category" sortable field="inventoryItem.category" className="col-l" />
          <Column header="UOM" field="inventoryItem.uom" className="col-m" />
          <Column header="Free Stock" body={this.freeStockBody} className="col-max-m" />
          <Column header="Incoming" body={this.incomingBody} className="col-max-s" />
          <Column header="Ordering [UOM]" body={this.orderingBody} className="col-max-l" />
        </TwoDataTable>
        <PurchaseOrderItemAddDialog
          key={purchaseOrder?.supplier?.id ?? supplier?.id}
          supplier={purchaseOrder?.supplier ?? supplier}
          showDialog={showAddDialog}
          onHide={this.onHideAddDialog}
          assignedPurchaseOrderItems={this.extractPurchaseOrderItems()}
          addPurchaseOrderItems={this.addPoItems}
        />
        {selectedGroup && (
          <PurchaseOrderItemAddSupplyItemDialog
            unselectedSupplyItems={
              selectedGroup.inventoryItem.supply_items?.filter(
                supi => !selectedGroup.purchaseOrderItems.find(poItem => poItem.supply_item_id === supi.id)
              ) ?? []
            }
            showDialog={showPoAddSupplyItemDialog}
            onSave={this.onPoAddSupplyItemDialSave}
            onHide={this.onPoAddSupplyItemDialHide}
          />
        )}
      </div>
    );
  }
}

export default PurchaseOrderItems;

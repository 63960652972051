import React from 'react';
import {Tooltip} from 'primereact/tooltip';
import {InventoryItem} from 'two-core';
import {getFreeStock} from '../../utils/InventoryItemUtil';
import {Divider} from 'primereact/divider';
interface Props {
  item: InventoryItem;
}
export const InventoryItemFreeStockColumn = ({item}: Props) => {
  const freeStock = getFreeStock(item);

  const id = `inventory_item_free_stock_${item.id}`;
  const tooltip = (
    <Tooltip target={`#${id}`} position="left">
      <div style={{width: '250px'}}>
        <div className="p-grid">
          <label className="p-col-12 p-md-7">on shelves</label>
          <div className="p-col-12 p-md-5">
            <span>{Math.round((item.current_stock_level ?? 0) * 100) / 100}</span>
          </div>
        </div>
        <div className="p-grid">
          <label className="p-col-12 p-md-7">(-) reserved</label>
          <div className="p-col-12 p-md-5">
            <span>{Math.round((item.reserved_qty ?? 0) * 100) / 100}</span>
          </div>
        </div>
        <div className="p-grid">
          <label className="p-col-12 p-md-7">(-) reorder limit</label>
          <div className="p-col-12 p-md-5">
            <span>{Math.round((item.reorder_limit ?? 0) * 100) / 100}</span>
          </div>
        </div>
        <Divider />
        <div className="p-grid">
          <label className="p-col-12 p-md-7">(=) free stock</label>
          <div className="p-col-12 p-md-5">
            <span>{Math.round(freeStock * 100) / 100}</span>
          </div>
        </div>
      </div>
    </Tooltip>
  );
  return (
    <>
      {tooltip}
      <span id={id}>{Math.round(freeStock * 100) / 100}</span>
    </>
  );
};

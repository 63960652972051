import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, Factory, QueryParameter} from 'two-core';

class FactoriesService extends ApiService {
  protected endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints?.factories ?? '';
  }

  async getFactories(params: QueryParameter): Promise<Factory[]> {
    return this.get(this.endpoint, params)
      .then(data => {
        return Promise.resolve((data as ApiListResponse).records as Factory[]);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async getFactory(id: string): Promise<Factory> {
    return this.get(this.endpoint + '/' + id)
      .then(data => {
        return Promise.resolve(data as unknown as Factory);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async getFactoriesForUser(userName: string): Promise<ApiListResponse> {
    const params: QueryParameter = {
      filters: [
        JSON.stringify({field: 'username', value: userName}),
        JSON.stringify({field: "factory.settings->>'app_key'", value: config().system.app_key}),
      ],
      aggregate: true,
    };
    if (!config().endpoints.users) {
      console.error('Missing Config: users endpoint.');
      Promise.reject();
    }
    console.log(params);
    return this.get(config().endpoints.users, params)
      .then(data => {
        console.log(data);
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async updateFactory(id: string, data: Factory): Promise<Factory> {
    return this.patch(this.endpoint + '/' + id, data)
      .then(data => {
        return Promise.resolve(data as Factory);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }
}

export default FactoriesService;

import React from 'react';
import {AppContext, TwoDialog, TwoToast} from 'two-app-ui';
import {Factory, InventoryItem, PurchaseOrder, QueryParameter, StockTransfer} from 'two-core';
import InventoryService from '../../../services/InventoryService';
import {DragDropContext, DropResult} from 'react-beautiful-dnd';
import {Skeleton} from 'primereact/skeleton';
import {EditStockTransferItemList, EditStockTransferInventoryItemListFilterProps} from './EditStockTransferItemList';
import './EditStockTransferDialog.scss';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {InputTextarea} from 'primereact/inputtextarea';
import FactoriesService from '../../../services/FactoriesService';
import {StockTransfersService} from '../../../services/StockTransferService';
import {IncomingPos} from '../../PurchaseOrder/PurchaseOrderItems';
import PurchaseOrdersService from '../../../services/PurchaseOrdersService';

export type EditStockTransferDialogMode = 'edit' | 'dispatch' | 'receive' | 'readonly';
interface Props {
  showDialog: boolean;
  onHide: () => void;
  stockTransfer?: StockTransfer;
  mode?: EditStockTransferDialogMode;
}

interface State {
  loading: boolean;
  lazyLoading: boolean;
  assignedInventoryItems: InventoryItem[];
  unassignedInventoryItems: InventoryItem[];
  inventoryItems?: InventoryItem[];
  totalInventoryItems?: number;
  inventoryItemsPagination: {
    pageSize: number;
    offset: number;
  };
  stockTransferInventoryItems?: InventoryItem[];
  filters: EditStockTransferInventoryItemListFilterProps;
  stockTransferPatch?: Partial<StockTransfer>;
  factories: Factory[];
  newQuantityMap: Map<InventoryItem['id'], number>;
  incomingPosMap: Map<InventoryItem['id'], IncomingPos>;
  loadingIncomingPos: boolean;
}

class EditStockTransferDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  inventoryService?: InventoryService;
  factoriesService?: FactoriesService;
  stockTransfersService?: StockTransfersService;
  purchaseOrdersService?: PurchaseOrdersService;
  twoToast?: TwoToast;
  observer?: IntersectionObserver;
  patrolRef = React.createRef<HTMLDivElement>();
  typingTimer?: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      lazyLoading: false,
      assignedInventoryItems: [],
      unassignedInventoryItems: [],
      inventoryItems: [],
      stockTransferInventoryItems: [],
      totalInventoryItems: 0,
      inventoryItemsPagination: {
        pageSize: 50,
        offset: 0,
      },
      filters: {},
      factories: [],
      newQuantityMap: new Map(),
      incomingPosMap: new Map(),
      loadingIncomingPos: false,
    };

    this.onHide = this.onHide.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onInventoryItemsLazyLoad = this.onInventoryItemsLazyLoad.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.loadData = this.loadData.bind(this);
    this.createStockTransfer = this.createStockTransfer.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onReceive = this.onReceive.bind(this);
  }

  componentDidMount() {
    this.inventoryService = this.context.inventoryService;
    this.factoriesService = this.context.factoriesService;
    this.stockTransfersService = this.context.stockTransfersService;
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.twoToast = this.context.twoToast;

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.onInventoryItemsLazyLoad();
      }
    }, options);
  }
  componentDidUpdate(prevProps: Props, prevState: State) {
    const {showDialog} = this.props;
    const {loading, lazyLoading} = this.state;
    if (loading || lazyLoading || !showDialog) {
      if (this.patrolRef?.current) {
        this.observer?.unobserve(this.patrolRef.current);
      }
    } else {
      if (this.patrolRef?.current) {
        this.observer?.observe(this.patrolRef.current);
      }
    }
  }

  componentWillUnmount() {
    if (this.patrolRef?.current) {
      this.observer?.unobserve(this.patrolRef.current);
    }
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async loadData() {
    const {stockTransfer, mode} = this.props;
    const {inventoryItemsPagination, filters, incomingPosMap} = this.state;
    const currentFactoryId = localStorage.getItem('current factory');
    let newIncomingPosMap = new Map(incomingPosMap);
    this.setState({loading: true});
    let inventoryItemsResult: {records: InventoryItem[]; total: number} | undefined = undefined;
    if (mode !== 'receive') {
      inventoryItemsResult = await this.loadInventoryItems(
        currentFactoryId!,
        filters,
        inventoryItemsPagination.pageSize,
        0
      );
      const loadedIncomingPosMap = await this.loadIncomingPos(
        inventoryItemsResult?.records.map(item => item.id!) ?? [],
        Array.from(newIncomingPosMap.keys()) as string[]
      );
      newIncomingPosMap = new Map([...Array.from(newIncomingPosMap), ...Array.from(loadedIncomingPosMap)]);
    }
    const factories = await this.loadFactories();
    let stockTransferInventoryItemsResult: {records: InventoryItem[]; total: number} | undefined = undefined;
    if (stockTransfer?.items?.data.length) {
      stockTransferInventoryItemsResult = await this.loadInventoryItems(currentFactoryId!, {
        ids:
          stockTransfer.items?.data.map(item =>
            stockTransfer.origin_factory_id === currentFactoryId ? item.original_item_id : item.destination_item_id
          ) ?? [],
      });
      const loadedIncomingPosMap = await this.loadIncomingPos(
        stockTransferInventoryItemsResult?.records.map(item => item.id!) ?? [],
        Array.from(newIncomingPosMap.keys()) as string[]
      );
      newIncomingPosMap = new Map([...Array.from(newIncomingPosMap), ...Array.from(loadedIncomingPosMap)]);
    }
    this.setState({
      loading: false,

      inventoryItems: inventoryItemsResult?.records,
      totalInventoryItems: inventoryItemsResult?.total,
      inventoryItemsPagination: {...inventoryItemsPagination, offset: inventoryItemsPagination.pageSize},

      stockTransferInventoryItems: stockTransferInventoryItemsResult?.records ?? [],
      factories: mode === 'receive' ? factories : factories.filter(factory => factory.id !== currentFactoryId),
      incomingPosMap: newIncomingPosMap,
    });
  }

  async loadInventoryItems(
    currentFactoryId: string,
    filters: EditStockTransferInventoryItemListFilterProps,
    pageSize?: number,
    offset?: number
  ) {
    try {
      const queryFilters = [JSON.stringify({field: 'factory_id', value: currentFactoryId})];
      if (filters?.name?.length) {
        queryFilters.push(JSON.stringify({field: 'name', value: filters.name, condition: 'iLike'}));
      }
      if (filters?.colour?.length) {
        queryFilters.push(JSON.stringify({field: 'colour', value: filters.colour, condition: 'iLike'}));
      }
      if (filters?.category?.length) {
        queryFilters.push(JSON.stringify({field: 'category', value: filters.category, condition: 'in'}));
      }
      if (filters?.sku?.length) {
        queryFilters.push(JSON.stringify({field: 'sku', value: filters.sku, condition: 'iLike'}));
      }
      if (filters?.ids?.length) {
        queryFilters.push(JSON.stringify({field: 'id', value: filters.ids, condition: 'in'}));
      }

      const queryParams: QueryParameter = {
        filters: queryFilters,
        offset,
        page_size: pageSize,
        orderBys: [JSON.stringify({field: 'name', direction: 'asc'})],
      };
      const response = await this.inventoryService!.getInventoryItems(queryParams);
      const records = (response?.records ?? []) as InventoryItem[];
      return {records, total: response?.total_records ?? 0};
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error loading inventory items');
      return undefined;
    }
  }
  async loadFactories() {
    //use try and catch like in loadStockTransfers and return value factories
    try {
      const filters: string[] = [];
      const queryParams: QueryParameter = {
        filters,
      };
      const response = await this.factoriesService!.getFactories(queryParams);
      return (response ?? []) as Factory[];
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error loading factories');
      return [];
    }
  }

  async loadIncomingPos(
    inventoryItemsIds: string[],
    excludedInventoryItemsIds: string[]
  ): Promise<Map<InventoryItem['id'], IncomingPos>> {
    const incomingPosMap = new Map<InventoryItem['id'], IncomingPos>();
    const filterInventoryItemsIds = inventoryItemsIds.filter(id => !excludedInventoryItemsIds.includes(id));
    if (!filterInventoryItemsIds.length) {
      return incomingPosMap;
    }
    this.setState({loadingIncomingPos: true});
    try {
      const filters: string[] = [
        JSON.stringify({
          field: 'stage',
          value: ['Ordered', 'Eta Confirmed', 'Delayed'],
          condition: 'in',
        }),
        JSON.stringify({
          field: 'purchase_order_item.inventory_item_id',
          value: inventoryItemsIds,
          condition: 'in',
        }),
      ];
      const params: QueryParameter = {
        filters,
        aggregate: ['items'],
      };
      let offset = 0;
      let totalRecords = 0;
      do {
        const response = await this.purchaseOrdersService!.getPurchaseOrders({...params, offset, page_size: 50});
        totalRecords = response?.total_records ?? 0;
        const pos = (response?.records ?? []) as PurchaseOrder[];
        for (const po of pos) {
          for (const poItem of po.items ?? []) {
            if (inventoryItemsIds.includes(poItem.inventory_item_id)) {
              const incoming4item = incomingPosMap.get(poItem.inventory_item_id) ?? {qty: 0, pos: []};
              incoming4item.qty += poItem.qty_in_uom ?? 0;
              if (!incoming4item.pos.find(existingPo => existingPo.id === po.id)) {
                incoming4item.pos.push(po);
              }
              incomingPosMap.set(poItem.inventory_item_id, incoming4item);
            }
          }
        }
        offset += 50;
      } while (offset < totalRecords);
      return incomingPosMap;
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error loading incoming POs');
      return incomingPosMap;
    } finally {
      this.setState({loadingIncomingPos: false});
    }
  }

  async createStockTransfer(data: Partial<StockTransfer>) {
    try {
      this.setState({loading: true});
      const newStockTransfer = await this.stockTransfersService!.createStockTransfer(data);
      if (!newStockTransfer) {
        this.twoToast?.showError('Stock Transfer creation failed');
        return;
      }
      this.twoToast?.showSuccess('Stock Transfer created successfully');
      this.onHide();
    } catch (e) {
      this.setState({loading: false});
      console.error(e);
      this.twoToast?.showError('Stock Transfer creation failed');
    }
  }

  async updateStockTransfer(stockTransferId: number, data: Partial<StockTransfer>) {
    try {
      this.setState({loading: true});
      const updatedStockTransfer = await this.stockTransfersService!.updateStockTransfer(stockTransferId, data);
      if (!updatedStockTransfer) {
        this.twoToast?.showError('Stock Transfer update failed');
        return;
      }
      this.twoToast?.showSuccess('Stock Transfer updated successfully');
      this.onHide();
    } catch (e) {
      this.setState({loading: false});
      console.error(e);
      this.twoToast?.showError('Stock Transfer update failed');
    }
  }

  async onInventoryItemsLazyLoad() {
    const {inventoryItemsPagination, inventoryItems, filters, incomingPosMap} = this.state;
    const currentFactoryId = localStorage.getItem('current factory')!;
    this.setState({lazyLoading: true});
    const result = await this.loadInventoryItems(
      currentFactoryId,
      filters,
      inventoryItemsPagination.pageSize,
      inventoryItemsPagination.offset
    );
    let newIncomingPosMap = new Map(incomingPosMap);
    const loadedIncomingPosMap = await this.loadIncomingPos(
      result?.records.map(item => item.id!) ?? [],
      Array.from(newIncomingPosMap.keys()) as string[]
    );
    newIncomingPosMap = new Map([...Array.from(newIncomingPosMap), ...Array.from(loadedIncomingPosMap)]);
    this.setState(
      {
        inventoryItems: [...(inventoryItems ?? []), ...(result?.records ?? [])],
        inventoryItemsPagination: {
          ...inventoryItemsPagination,
          offset: inventoryItemsPagination.offset + inventoryItemsPagination.pageSize,
        },
        incomingPosMap: newIncomingPosMap,
        lazyLoading: false,
      },
      () => {
        if (this.patrolRef?.current) {
          this.observer?.observe(this.patrolRef.current);
        }
      }
    );
  }

  onHide() {
    this.setState({
      loading: false,
      inventoryItems: [],
      assignedInventoryItems: [],
      unassignedInventoryItems: [],
      stockTransferInventoryItems: [],
      totalInventoryItems: 0,
      newQuantityMap: new Map(),
      stockTransferPatch: undefined,
      incomingPosMap: new Map(),
      loadingIncomingPos: false,
    });
    this.props.onHide();
  }

  onShow() {
    this.loadData();
  }

  onAdd() {
    const {stockTransferPatch, assignedInventoryItems, newQuantityMap} = this.state;
    if (!stockTransferPatch?.reference) {
      this.twoToast?.showError('Reference is required');
      return;
    }
    if (!stockTransferPatch?.destination_factory_id) {
      this.twoToast?.showError('Destination factory is required');
      return;
    }
    if (assignedInventoryItems.length) {
      for (const assignedInventoryItem of assignedInventoryItems) {
        if (!newQuantityMap.get(assignedInventoryItem.id)) {
          this.twoToast?.showError('Quantity is required');
          return;
        }
      }
    }
    const updatedStockTransferPatch: Partial<StockTransfer> = {
      ...stockTransferPatch,
      stage: 'Draft',
      origin_factory_id: localStorage.getItem('current factory')!,
      items: {
        count: assignedInventoryItems.length,
        data: assignedInventoryItems.map(item => ({
          original_item_id: item.id!,
          destination_item_id: '',
          transferred_qty: newQuantityMap.get(item.id)!,
        })),
      },
    };
    this.createStockTransfer(updatedStockTransferPatch);
  }

  onEdit() {
    const {stockTransfer, mode} = this.props;
    const {stockTransferPatch, assignedInventoryItems, unassignedInventoryItems, newQuantityMap} = this.state;
    if (stockTransferPatch?.reference !== undefined && !stockTransferPatch.reference) {
      this.twoToast?.showError('Reference is required');
      return;
    }
    const updatedStockTransferPatch: Partial<StockTransfer> = {
      ...stockTransferPatch,
    };
    if (assignedInventoryItems.length || unassignedInventoryItems.length || newQuantityMap.size) {
      const items = [];
      const unassignedInventoryItemsIds = unassignedInventoryItems.map(item => item.id);
      for (const stockTransferItem of stockTransfer?.items?.data ?? []) {
        if (unassignedInventoryItemsIds.includes(stockTransferItem.original_item_id)) {
          continue;
        }
        const quantity = newQuantityMap.has(stockTransferItem.original_item_id)
          ? newQuantityMap.get(stockTransferItem.original_item_id)
          : stockTransferItem.transferred_qty;
        if (!quantity) {
          this.twoToast?.showError('Quantity is required');
          return;
        }
        items.push({
          original_item_id: stockTransferItem.original_item_id,
          destination_item_id: stockTransferItem.destination_item_id,
          transferred_qty: quantity,
        });
      }
      for (const assignedInventoryItem of assignedInventoryItems) {
        const quantity = newQuantityMap.get(assignedInventoryItem.id);
        if (!quantity) {
          this.twoToast?.showError('Quantity is required');
          return;
        }
        items.push({
          original_item_id: assignedInventoryItem.id!,
          destination_item_id: '',
          transferred_qty: quantity,
        });
      }
      updatedStockTransferPatch.items = {
        count: items.length,
        data: items,
      };
    }
    //todo get current user full name from UsersService (It will be implemented in CYBORG-1972)
    const user = this.getCurrentUser();
    if (mode === 'dispatch') {
      updatedStockTransferPatch.stage = 'In Transit';
      updatedStockTransferPatch.dispatched_at = new Date();
      updatedStockTransferPatch.dispatched_by = {
        user_id: user.id,
        label: user.username,
      };
    }
    if (!Object.entries(updatedStockTransferPatch).length) {
      this.twoToast?.showInfo('No changes made');
      this.onHide();
      return;
    }
    this.updateStockTransfer(stockTransfer!.id, updatedStockTransferPatch);
  }

  onReceive() {
    const {stockTransfer} = this.props;
    const {stockTransferPatch} = this.state;
    //todo get current user full name from UsersService (It will be implemented in CYBORG-1972)
    const user = this.getCurrentUser();
    const updatedStockTransferPatch: Partial<StockTransfer> = {
      ...stockTransferPatch,
      stage: 'Received',
      received_at: new Date(),
      received_by: {
        user_id: user.id,
        label: user.username,
      },
    };
    this.updateStockTransfer(stockTransfer!.id, updatedStockTransferPatch);
  }

  onDragEnd(result: DropResult) {
    const {inventoryItems, assignedInventoryItems, unassignedInventoryItems} = this.state;
    const {source, destination} = result;
    if (source?.droppableId === destination?.droppableId) {
      return;
    }
    const destinationId = destination?.droppableId;
    const draggedInvItemId = result.draggableId.split('_')[0];
    if (destinationId === 'assignable_inventory_items_list') {
      if (assignedInventoryItems.find(item => item.id === draggedInvItemId)) {
        // revert back newly assigned item
        this.setState({assignedInventoryItems: assignedInventoryItems.filter(item => item.id !== draggedInvItemId)});
      } else {
        // unassign item from stock transfer
        this.setState({
          unassignedInventoryItems: [
            ...unassignedInventoryItems,
            inventoryItems!.find(item => item.id === draggedInvItemId)!,
          ],
        });
      }
    } else if (destinationId === 'assigned_inventory_items_list') {
      const invItem = inventoryItems!.find(item => item.id === draggedInvItemId)!;
      if (unassignedInventoryItems.find(unassignedItem => unassignedItem.id === invItem.id)) {
        // revert back unassigned item
        this.setState({unassignedInventoryItems: unassignedInventoryItems.filter(item => item.id !== invItem.id)});
      } else {
        // assign new item to stock transfer
        this.setState({assignedInventoryItems: [...assignedInventoryItems, invItem]});
      }
    }
  }

  onFilterChange = (filters: Partial<EditStockTransferInventoryItemListFilterProps>) => {
    this.setState(
      state => ({filters: {...state.filters, ...filters}}),
      () => {
        if (this.typingTimer) {
          clearTimeout(this.typingTimer);
        }
        this.typingTimer = setTimeout(this.loadData, 2000);
      }
    );
  };

  getCurrentUser() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = unparsedUser ? JSON.parse(unparsedUser) : undefined;
    const currentUserUsername = currentUser?.username ?? '';
    const currentUserId = currentUser.uuid as string;

    return {
      username: currentUserUsername,
      id: currentUserId,
    };
  }

  render() {
    const {stockTransfer, showDialog, mode} = this.props;
    const {
      loading,
      inventoryItems,
      inventoryItemsPagination,
      filters,
      totalInventoryItems,
      stockTransferInventoryItems,
      unassignedInventoryItems,
      assignedInventoryItems,
      lazyLoading,
      stockTransferPatch,
      factories,
      newQuantityMap,
      loadingIncomingPos,
      incomingPosMap,
    } = this.state;

    const stockTransferItems = stockTransfer?.items?.data ?? [];
    const stockTransferOriginalItemsIds = stockTransferItems.map(item => item.original_item_id);
    const assignedInvItemsIds = assignedInventoryItems.map(item => item.id);
    const unAssignedInvItemsIds = unassignedInventoryItems.map(item => item.id);
    const assignableInventoryItems =
      inventoryItems?.filter(item => {
        if (assignedInvItemsIds?.includes(item.id!)) {
          return false;
        }
        if (stockTransferOriginalItemsIds?.includes(item.id!)) {
          if (!unAssignedInvItemsIds?.includes(item.id!)) {
            return false;
          }
        }
        return true;
      }) ?? [];
    const allAssignedInvItems = [
      ...assignedInventoryItems,
      ...(stockTransferInventoryItems?.filter(item => !unAssignedInvItemsIds.includes(item.id)) ?? []),
    ];

    let inventoryItemsPatrolElement = undefined;
    if (inventoryItemsPagination.offset < (totalInventoryItems ?? 0)) {
      //create patrol item at the end of the list
      const patrolItem: InventoryItem = {
        id: 'patrol',
        name: '',
        colour: '',
        sku: '',
        category: '',
        uom: '',
        factory_id: '',
        type: '',
        updated_at: new Date(),
      };
      assignableInventoryItems.push(patrolItem);
      inventoryItemsPatrolElement = (
        <div ref={this.patrolRef} className="w-100">
          <Skeleton />
        </div>
      );
    }

    const quantityMap = new Map(newQuantityMap);
    for (const stockTransferItem of stockTransferItems) {
      if (!quantityMap.has(stockTransferItem.original_item_id)) {
        quantityMap.set(stockTransferItem.original_item_id, stockTransferItem.transferred_qty);
      }
      if (stockTransferItem.destination_item_id && !quantityMap.has(stockTransferItem.destination_item_id)) {
        quantityMap.set(stockTransferItem.destination_item_id, stockTransferItem.transferred_qty);
      }
    }
    let header = 'Create Stock Transfer';
    let saveButtonTitle = 'Create';
    let onSaveAction: (() => void) | undefined = this.onAdd;
    if (stockTransfer) {
      if (mode === 'dispatch') {
        header = 'Dispatching Stock Transfer';
        saveButtonTitle = 'Dispatch';
        onSaveAction = this.onEdit;
      } else if (mode === 'receive') {
        header = 'Receiving Stock Transfer';
        saveButtonTitle = 'Received';
        onSaveAction = this.onReceive;
      } else if (mode === 'edit') {
        header = 'Edit Stock Transfer';
        saveButtonTitle = 'Save';
        onSaveAction = this.onEdit;
      } else {
        header = 'Stock transfer';
        saveButtonTitle = '';
        onSaveAction = undefined;
      }
    }

    return (
      <TwoDialog
        header={header}
        showDialog={showDialog}
        visible={showDialog}
        style={{width: '75vw', height: '90vh'}}
        breakpoints={{'768px': '80vw', '576px': '90vw'}}
        onHide={this.onHide}
        onSave={onSaveAction}
        onShow={this.onShow}
        saveButtonTitle={saveButtonTitle}
        className={'edit-stock-transfer-dialog'}
      >
        <div className="p-d-flex p-flex-column p-fluid h-100 w-100">
          <div className="p-field p-grid">
            <label className="p-col-2">reference</label>
            <div className="p-col-4">
              <InputText
                value={
                  stockTransferPatch?.reference !== undefined ? stockTransferPatch.reference : stockTransfer?.reference
                }
                onChange={e =>
                  this.setState({
                    stockTransferPatch: {
                      ...stockTransferPatch,
                      reference: e.target.value,
                    },
                  })
                }
                disabled={mode === 'receive' || mode === 'readonly'}
              />
            </div>
            <label className="p-col-2">destination</label>
            <div className="p-col-4">
              <Dropdown
                value={
                  stockTransferPatch?.destination_factory_id !== undefined
                    ? stockTransferPatch.destination_factory_id
                    : stockTransfer?.destination_factory_id
                }
                onChange={e =>
                  this.setState({
                    stockTransferPatch: {
                      ...stockTransferPatch,
                      destination_factory_id: e.target.value,
                    },
                  })
                }
                optionLabel="name_long"
                optionValue="id"
                options={factories}
                disabled={mode === 'receive' || mode === 'readonly'}
              />
            </div>
          </div>
          <div style={{flexGrow: 2}}>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <div className="w-100 h-100">
                {mode !== 'receive' && mode !== 'readonly' && (
                  <>
                    <div id="assignable_inventory_items_list_container">
                      <EditStockTransferItemList
                        items={assignableInventoryItems}
                        droppableId={'assignable_inventory_items_list'}
                        pageSizeIdentifier={'assignable_inventory_items_list_container'}
                        patrolElement={inventoryItemsPatrolElement}
                        onFilterChange={this.onFilterChange}
                        filters={filters}
                        loading={loading}
                        incomingPosMap={incomingPosMap}
                        loadingIncomingPos={loadingIncomingPos}
                      />
                    </div>
                    <div className="inventory-item-list-drag-and-drop-title">Drag and drop</div>
                  </>
                )}
                <div id="assigned_inventory_items_list_container" className="p-my-auto">
                  <EditStockTransferItemList
                    items={allAssignedInvItems}
                    droppableId={'assigned_inventory_items_list'}
                    pageSizeIdentifier={'assigned_inventory_items_list_container'}
                    loading={loading}
                    quantityMap={quantityMap}
                    onQuantityChange={(itemId, quantity) =>
                      this.setState(state => ({newQuantityMap: new Map(state.newQuantityMap.set(itemId, quantity))}))
                    }
                    readonly={mode === 'receive' || mode === 'readonly'}
                    incomingPosMap={incomingPosMap}
                    loadingIncomingPos={loadingIncomingPos}
                  />
                </div>
              </div>
            </DragDropContext>
          </div>
          <div className="p-field p-grid">
            <label className="p-col-2">note</label>
            <div className="p-col-10">
              <InputTextarea
                value={stockTransferPatch?.note !== undefined ? stockTransferPatch.note : stockTransfer?.note}
                onChange={e =>
                  this.setState({
                    stockTransferPatch: {
                      ...stockTransferPatch,
                      note: e.target.value,
                    },
                  })
                }
                className="w-100"
                rows={5}
                cols={30}
                disabled={mode === 'readonly'}
              />
            </div>
          </div>
        </div>
      </TwoDialog>
    );
  }
}

export default EditStockTransferDialog;

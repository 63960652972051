import {Column} from 'primereact/column';
import {DataTablePageParams, DataTableSortOrderType, DataTableSortParams} from 'primereact/datatable';
import {MenuItem, MenuItemOptions} from 'primereact/menuitem';
import React, {Component} from 'react';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  TwoDataTable,
  TwoMessage,
  TwoToast,
} from 'two-app-ui';
import {Factory, QueryParameter, StockTransfer} from 'two-core';
import {Subscription} from 'rxjs';
import {StockTransfersService} from '../../services/StockTransferService';
import FactoriesService from '../../services/FactoriesService';
import './StockTransferList.scss';
import formats from '../../config/formats';
import {DateTime} from 'luxon';
import {Tooltip} from 'primereact/tooltip';
import {
  faArrowCircleRight,
  faPencil,
  faPlus,
  faToggleOff,
  faToggleOn,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import {confirmDialog} from 'primereact/confirmdialog';
import {InputText} from 'primereact/inputtext';
import {MultiSelect} from 'primereact/multiselect';
import EditStockTransferDialog, {EditStockTransferDialogMode} from './EditStockTransferDialog/EditStockTransferDialog';

interface State {
  loading: boolean;
  showEditDialog?: boolean;
  stockTransfers: StockTransfer[];
  totalStockTransfers: number;
  selectedStockTransfer?: StockTransfer;
  factoriesMap: Map<Factory['id'], Factory>;
  filter?: {
    showReceived?: boolean;
    reference?: string;
    stage?: string[];
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy?: {
    field: string;
    order: DataTableSortOrderType;
  };
  editDialogMode?: EditStockTransferDialogMode;
}

export class StockTransferList extends Component<{}, State> {
  static contextType = AppContext;
  subscription?: Subscription;
  stockTransfersService?: StockTransfersService;
  factoriesService?: FactoriesService;
  twoToast?: TwoToast;

  constructor(props: {}) {
    super(props);

    this.state = {
      loading: false,
      stockTransfers: [],
      totalStockTransfers: 0,
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      factoriesMap: new Map<Factory['id'], Factory>(),
      sortBy: {field: 'stage', order: 1},
    };
    this.loadData = this.loadData.bind(this);
    this.onSort = this.onSort.bind(this);
    this.headerMenuItems = this.headerMenuItems.bind(this);
    this.rowMenuItems = this.rowMenuItems.bind(this);
    this.onHideEditDialog = this.onHideEditDialog.bind(this);
    this.referenceBody = this.referenceBody.bind(this);
    this.deleteStockTransfer = this.deleteStockTransfer.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.noteFilterTemplate = this.noteFilterTemplate.bind(this);
    this.stageFilterTemplate = this.stageFilterTemplate.bind(this);
    this.referenceFilterTemplate = this.referenceFilterTemplate.bind(this);
  }

  componentDidMount() {
    this.stockTransfersService = this.context.stockTransfersService;
    this.factoriesService = this.context.factoriesService;
    this.twoToast = this.context.twoToast;
    this.subscription = MessageService.getMessage().subscribe(message => {
      const castedMessage = message as TwoMessage;
      if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
        this.loadData();
      }
    });

    this.loadData();
  }

  componentWillUnmount() {
    this.subscription?.unsubscribe();
  }

  async loadData() {
    const {sortBy, factoriesMap, filter, pagination} = this.state;
    this.setState({loading: true});
    const {stockTransfers, totalStockTransfers} = await this.loadStockTransfers(
      filter,
      sortBy,
      pagination.pageSize,
      pagination.offset
    );
    const missingFactoryIdsSet = new Set<string>();
    for (const stockTransfer of stockTransfers) {
      if (!factoriesMap.has(stockTransfer.origin_factory_id)) {
        missingFactoryIdsSet.add(stockTransfer.origin_factory_id);
      }
      if (!factoriesMap.has(stockTransfer.destination_factory_id)) {
        missingFactoryIdsSet.add(stockTransfer.destination_factory_id);
      }
    }
    const newFactoriesMap = new Map(factoriesMap);
    if (missingFactoryIdsSet.size) {
      const missingFactoryIds = Array.from(missingFactoryIdsSet);
      const factories = await this.loadFactories(missingFactoryIds);
      for (const factory of factories) {
        newFactoriesMap.set(factory.id, factory);
      }
    }

    this.setState({
      loading: false,
      stockTransfers,
      totalStockTransfers: totalStockTransfers ?? 0,
      factoriesMap: newFactoriesMap,
    });
  }

  async loadStockTransfers(
    filter?: {
      showReceived?: boolean;
      reference?: string;
      stage?: string[];
      note?: string;
    },
    sortBy?: {
      field: string;
      order: DataTableSortOrderType;
    },
    pageSize?: number,
    offset?: number
  ) {
    try {
      const filters: string[] = [];
      if (filter?.stage?.length) {
        filters.push(JSON.stringify({field: 'stage', value: filter.stage, condition: 'in'}));
      } else {
        if (!filter?.showReceived) {
          filters.push(JSON.stringify({field: 'stage', value: 'Received', condition: '<>'}));
        }
      }
      if (filter?.reference) {
        filters.push(JSON.stringify({field: 'reference', value: filter.reference, condition: 'iLike'}));
      }
      if (filter?.note) {
        filters.push(JSON.stringify({field: 'note', value: filter.note, condition: 'iLike'}));
      }
      let orderBys = undefined;
      if (sortBy) {
        orderBys = [JSON.stringify({field: sortBy.field, direction: sortBy.order === 1 ? 'asc' : 'desc'})];
      }
      const queryParams: QueryParameter = {
        page_size: pageSize,
        offset,
        orderBys,
        filters,
      };
      const response = await this.stockTransfersService!.getStockTransfers(queryParams);
      const stockTransfers = (response?.records ?? []) as StockTransfer[];
      const totalStockTransfers = response?.total_records ?? 0;
      return {stockTransfers, totalStockTransfers};
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error loading stock transfer');
      return {stockTransfers: [], totalStockTransfers: 0};
    }
  }

  async loadFactories(ids: string[]) {
    //use try and catch like in loadStockTransfers and return value factories
    try {
      const filters: string[] = [];
      const queryParams: QueryParameter = {
        filters,
      };
      const response = await this.factoriesService!.getFactories(queryParams);
      return (response ?? []) as Factory[];
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error loading factories');
      return [];
    }
  }

  async deleteStockTransfer(id: number) {
    try {
      this.setState({loading: true});
      await this.stockTransfersService!.deleteStockTransfer(id);
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error deleting stock transfer');
    } finally {
      this.setState({loading: false});
      this.loadData();
    }
  }

  async onSort(e: DataTableSortParams) {
    this.setState({sortBy: {field: e.sortField, order: e.sortOrder}}, () => this.loadData());
  }

  async onPageChange(e: DataTablePageParams) {
    this.setState({pagination: {offset: e.first, pageSize: e.rows}}, () => this.loadData());
  }

  async onFilterChange(name: string, value: string) {
    this.setState({filter: {...this.state.filter, [name]: value}}, () => this.loadData());
  }

  onHideEditDialog() {
    this.setState({showEditDialog: false, selectedStockTransfer: undefined});
    this.loadData();
  }
  headerMenuItems(): MenuItem[] {
    const {filter} = this.state;
    const menuItems: MenuItem[] = [];
    menuItems.push({
      label: 'Add',
      faIcon: faPlus,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => this.setState({showEditDialog: true, editDialogMode: 'edit', selectedStockTransfer: undefined}),
    });
    menuItems.push({
      label: filter?.showReceived ? 'Hide Received' : 'Show Received',
      faIcon: filter?.showReceived ? faToggleOff : faToggleOn,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () =>
        this.setState(
          () => ({filter: {...filter, showReceived: !filter?.showReceived}}),
          () => this.loadData()
        ),
    });
    return menuItems;
  }

  rowMenuItems(stockTransfer: StockTransfer): MenuItem[] {
    const currentFactoryId = localStorage.getItem('current factory');
    const menuItems: MenuItem[] = [];
    if (stockTransfer.stage === 'Draft') {
      if (currentFactoryId === stockTransfer.origin_factory_id) {
        menuItems.push({
          label: 'Edit',
          faIcon: faPencil,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.setState({selectedStockTransfer: stockTransfer, showEditDialog: true, editDialogMode: 'edit'});
          },
        });
        menuItems.push({
          label: 'Sent',
          faIcon: faArrowCircleRight,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.setState({selectedStockTransfer: stockTransfer, showEditDialog: true, editDialogMode: 'dispatch'});
          },
        });
        menuItems.push({
          label: 'Delete',
          faIcon: faTrashAlt,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            const message = (
              <div>
                <div>{`Are you sure you want to delete the stock transfer ${stockTransfer.reference}?`}</div>
                <div>Please note, this action cannot be reverted.</div>
              </div>
            );
            confirmDialog({
              message: message,
              header: 'Delete Stock Transfer',
              accept: () => {
                this.deleteStockTransfer(stockTransfer.id!);
              },
            });
          },
        });
      }
    }
    if (stockTransfer.stage === 'In Transit') {
      if (currentFactoryId === stockTransfer.destination_factory_id) {
        menuItems.push({
          label: 'Received',
          faIcon: faArrowCircleRight,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.setState({selectedStockTransfer: stockTransfer, showEditDialog: true, editDialogMode: 'receive'});
          },
        });
      }
    }
    return menuItems;
  }

  referenceBody(rowData: StockTransfer) {
    return (
      <AppColumnMenuBodyTemplate
        key={rowData.id}
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={() => this.rowMenuItems(rowData)}
        selectedItems={[]}
      >
        <div
          className="stock-transfer-reference-column"
          onClick={() =>
            this.setState(() => ({
              selectedStockTransfer: rowData,
              editDialogMode: 'readonly',
              showEditDialog: true,
            }))
          }
        >
          {rowData.reference}
        </div>
      </AppColumnMenuBodyTemplate>
    );
  }

  stageBody(rowData: StockTransfer) {
    return (
      <span
        className={`stock-transfer-stage-badge stock-transfer-stage-${(rowData.stage ?? '').toLowerCase().replaceAll(' ', '-')}`}
      >
        {rowData.stage}
      </span>
    );
  }

  factoryBody(rowData: StockTransfer, factoryType: 'origin' | 'destination') {
    const factoryId = factoryType === 'origin' ? rowData.origin_factory_id : rowData.destination_factory_id;
    return this.state.factoriesMap.get(factoryId)?.name_long ?? 'Unknown factory';
  }

  dateBody(rowData: StockTransfer, dateField: 'dispatched_at' | 'received_at') {
    const date = rowData[dateField];
    const dateAdditionalInfo = dateField === 'dispatched_at' ? rowData.dispatched_by : rowData.received_by;
    const content = (
      <div id={`${dateField}-${rowData.id}`}>
        <span>{date ? DateTime.fromISO(date?.toString()).toFormat(formats.dateTime) : ''}</span>
        <span>{dateAdditionalInfo?.label}</span>
      </div>
    );
    return (
      <div>
        {content}
        <Tooltip target={`#${dateField}-${rowData.id}`} showDelay={500} mouseTrack mouseTrackLeft={15}>
          {content}
        </Tooltip>
      </div>
    );
  }

  noteBody(rowData: StockTransfer) {
    return (
      <div>
        <div id={`note-${rowData.id}`}>{rowData.note}</div>
        <Tooltip target={`#note-${rowData.id}`} showDelay={500} mouseTrack mouseTrackLeft={15} position="left">
          {rowData.note}
        </Tooltip>
      </div>
    );
  }

  referenceFilterTemplate() {
    return (
      <InputText
        name="name"
        className="form-filter"
        onBlur={e => {
          this.onFilterChange('reference', e.target.value);
        }}
      />
    );
  }

  stageFilterTemplate() {
    const {filter} = this.state;
    return (
      <MultiSelect
        value={filter?.stage}
        options={['Draft', 'In Transit', 'Received']}
        name="stage"
        className="form-filter stage-filter"
        onChange={e => {
          this.onFilterChange('stage', e.value);
        }}
        showClear
      />
    );
  }

  noteFilterTemplate() {
    return (
      <InputText
        name="note"
        className="form-filter"
        onBlur={e => {
          this.onFilterChange('note', e.target.value);
        }}
      />
    );
  }

  render() {
    const {
      loading,
      editDialogMode,
      stockTransfers,
      pagination,
      totalStockTransfers,
      selectedStockTransfer,
      sortBy,
      showEditDialog,
    } = this.state;
    return (
      <div className="stock-transfer-table">
        <TwoDataTable
          loading={loading}
          value={stockTransfers}
          activeFilters={{}}
          selectedItems={[]}
          onSort={this.onSort}
          sortField={sortBy?.field}
          sortOrder={sortBy?.order}
          initMenuItems={this.headerMenuItems}
          isMenuDynamic
          rows={pagination.pageSize}
          first={pagination.offset}
          totalRecords={totalStockTransfers}
          onPage={this.onPageChange}
        >
          <Column
            header="Reference"
            body={this.referenceBody}
            className="col-min-xl"
            sortField="reference"
            sortable
            filter
            filterElement={this.referenceFilterTemplate()}
            showFilterMenu={false}
          />
          <Column
            header="Stage"
            body={this.stageBody}
            className="col-m"
            filter
            filterElement={this.stageFilterTemplate()}
            showFilterMenu={false}
          />
          <Column
            header="Origin"
            body={rowData => this.factoryBody(rowData, 'origin')}
            className="col-min-m col-max-xl"
          />
          <Column
            header="Destination"
            body={rowData => this.factoryBody(rowData, 'destination')}
            className="col-min-m col-max-xl"
          />
          <Column
            header="Dispatched"
            body={rowData => this.dateBody(rowData, 'dispatched_at')}
            sortField="dispatched_at"
            sortable
            className="col-min-m col-max-xxl"
          />
          <Column
            header="Received"
            body={rowData => this.dateBody(rowData, 'received_at')}
            sortField="dispatched_at"
            sortable
            className="col-xxl"
          />
          <Column
            header="Note"
            body={this.noteBody}
            className="col-min-xl"
            filter
            filterElement={this.noteFilterTemplate}
            showFilterMenu={false}
          />
        </TwoDataTable>
        <EditStockTransferDialog
          showDialog={showEditDialog ?? false}
          onHide={this.onHideEditDialog}
          stockTransfer={selectedStockTransfer}
          mode={editDialogMode}
        />
      </div>
    );
  }
}
